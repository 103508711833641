const products = [
  {
    name: "ACCURSIO TAVOLINO CM.30 X 40 H.45",
    family: "ACCURSIO",
    id: "9031",
    code: "U2WT3XFMGY",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "4SDO5662KB",
  },
  {
    name: "ACCURSIO TAVOLINO CM.35 X 35 H.40",
    family: "ACCURSIO",
    id: "9030",
    code: "YQG2HAR8HF",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "5WFKVQ4NH7",
  },
  {
    name: "ACCURSIO TAVOLINO CM.35 X 50 H.40",
    family: "ACCURSIO",
    id: "9032",
    code: "NY4X3CNL9J",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "BKT3RTMPVD",
  },
  {
    name: "ACCURSIO TAVOLINO CM.35 X 60 H.50",
    family: "ACCURSIO",
    id: "9034",
    code: "AQQYEHGG1O",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "DRBY3GW6TJ",
  },
  {
    name: "ACCURSIO TAVOLINO CM.40 X 140 H.75",
    family: "ACCURSIO",
    id: "9039",
    code: "MT21PKA6MM",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "PPNV4GYFBR",
  },
  {
    name: "ACCURSIO TAVOLINO CM.40 X 40 H.45",
    family: "ACCURSIO",
    id: "9033",
    code: "8RNXM4ET3H",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "",
  },
  {
    name: "ACCURSIO TAVOLINO CM.40 X 80 H.35",
    family: "ACCURSIO",
    id: "9037",
    code: "ZI9388LIAK",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "YWZ3AGHB86",
  },
  {
    name: "ACCURSIO TAVOLINO CM.45 X 45 H.85",
    family: "ACCURSIO",
    id: "9038",
    code: "RC3JSFM376",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "GGV9JAYTFI",
  },
  {
    name: "ACCURSIO TAVOLINO CM.50 X 50 H.35",
    family: "ACCURSIO",
    id: "9035",
    code: "NPEE2191ZT",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "HQ7PH7M4IF",
  },
  {
    name: "ACCURSIO TAVOLINO CM.70 X 70 H.40",
    family: "ACCURSIO",
    id: "9036",
    code: "G3BXH6RIHZ",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "CRVP2C4RRZ",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 35 H.40",
    family: "ACCURSIO",
    id: "9040",
    code: "ZTRT9CRZFC",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "E39G9ROJ46",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 40 H.45",
    family: "ACCURSIO",
    id: "9041",
    code: "OYJQ5Z76TX",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "R0FQHSTD1N",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 45 H.85",
    family: "ACCURSIO",
    id: "9045",
    code: "1A3XT8DVUH",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "1HBWYJDXV6",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 50 H.35",
    family: "ACCURSIO",
    id: "9042",
    code: "OYJQ5Z76TX",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "R0FQHSTD1N",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 60 H.60",
    family: "ACCURSIO",
    id: "9044",
    code: "LDYKTWLMZK",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "CPK98L5L6D",
  },
  {
    name: "ACCURSIO TAVOLINO DIAMETRO 80 H.40",
    family: "ACCURSIO",
    id: "9043",
    code: "85URGX0BBB",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "C8PYZCSTLJ",
  },
  {
    name: "AGETTI POLTRONA",
    id: "1580",
    code: "5SBW34RHAC",
    family: "Agetti",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "N722VPEQNV",
  },
  {
    name: "AGETTI DIVANO 2 POSTI",
    id: "1581",
    code: "X8L02VUZO5",
    family: "Agetti",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "6I07MU7GQE",
  },
  {
    name: "ALA DIVANO ALTO - BASE LEGNO",
    id: "8886N",
    code: "6BKD8HGGB0",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OXBVMG7VO0",
  },
  {
    name: "ALA DIVANO ALTO - BASE METALLO",
    id: "8876N",
    code: "H6XTFJW3S5",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "U4B31IO7JZ",
  },
  {
    name: "ALA DIVANO ALTO CAPITONNE - BASE METALLO",
    id: "8903N",
    code: "3J83QT095R",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "HTIWMBUSEO",
  },
  {
    name: "ALA DIVANO ALTO CAPITONNE - BASE LEGNO",
    id: "8908N",
    code: "KKJU766X2H",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "ACLH0V741P",
  },
  {
    name: "ALA DIVANO ALTO CON BRACCIOLI - BASE LEGNO",
    id: "8887N",
    code: "M2Z26H56BX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "PBH8E5WH38",
  },
  {
    name: "ALA DIVANO ALTO CON BRACCIOLI - BASE METALLO",
    id: "8877N",
    code: "YVWJDA5LZ8",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "CB9237BQMJ",
  },
  {
    name: "ALA DIVANO BASSO - BASE LEGNO",
    id: "8882N",
    code: "AJWUKGUA3S",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OUPS6RC58K",
  },
  {
    name: "ALA DIVANO BASSO - BASE METALLO",
    id: "8872N",
    code: "VJ2813IWQO",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "7MRSL4VMMZ",
  },
  {
    name: "ALA DIVANO BASSO CAPITONNE - BASE LEGNO",
    id: "8906N",
    code: "SK6GFVVUFN",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "03UKOORAEK",
  },
  {
    name: "ALA DIVANO BASSO CAPITONNE - BASE METALLO",
    id: "8901N",
    code: "5BONGXODB1",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "LI2MG9BER1",
  },
  {
    name: "ALA DIVANO BASSO CON BRACCIOLI - BASE LEGNO",
    id: "8883N",
    code: "WCNKBTCH8B",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "UJ7ZQN98V3",
  },
  {
    name: "ALA DIVANO BASSO CON BRACCIOLI - BASE METALLO",
    id: "8873N",
    code: "P6TUZ5FLSP",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "FORLUB8H9K",
  },
  {
    name: "ALA POLTRONA ALTA  - BASE LEGNO",
    id: "8884N",
    code: "AJWUKGUA3S",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "OUPS6RC58K",
  },
  {
    name: "ALA POLTRONA ALTA  - BASE METALLO",
    id: "8874N",
    code: "78ULN1CG20",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "XJIGTTDHOG",
  },
  {
    name: "ALA POLTRONA ALTA CAPITONNE\u0027 - BASE LEGNO",
    id: "8907N",
    code: "7GS8O4GN2N",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "EAR3FA974E",
  },
  {
    name: "ALA POLTRONA ALTA CAPITONNE\u0027- BASE METALLO",
    id: "8902N",
    code: "QS7BD3ZZSD",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "BL0AWRP6A7",
  },
  {
    name: "ALA POLTRONA ALTA CON BRACCIOLI - BASE LEGNO",
    id: "8885N",
    code: "R6CD5OS0L6",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "IWNQB2TNCG",
  },
  {
    name: "ALA POLTRONA ALTA CON BRACCIOLI - BASE METALLO",
    id: "8875N",
    code: "0JZOS1BKGJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "X3CE0ESINP",
  },
  {
    name: "ALA POLTRONA BASSA - BASE LEGNO",
    id: "8880N",
    code: "TWJJR2BWL9",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "5RS2IAV8EV",
  },
  {
    name: "ALA POLTRONA BASSA - BASE METALLO",
    id: "8870N",
    code: "CDLU0TNZIJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "XZ5J8BX7ET",
  },
  {
    name: "ALA POLTRONA BASSA CAPITONNE\u0027 - BASE LEGNO",
    id: "8905N",
    code: "LLC4V3Q65B",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "8AX0ZSNHEF",
  },
  {
    name: "ALA POLTRONA BASSA CAPITONNE\u0027 - BASE METALLO",
    id: "8900N",
    code: "RT35GMM2XX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "CWPPLVDQLF",
  },
  {
    name: "ALA POLTRONA BASSA CON BRACCIOLI - BASE LEGNO",
    id: "8881N",
    code: "MUV3DR5JMD",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "GGJFZ91P7P",
  },
  {
    name: "ALA POLTRONA BASSA CON BRACCIOLI - BASE METALLO",
    id: "8871N",
    code: "WEYNCT2BN6",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "NEPQ8OP8GR",
  },
  {
    name: "ALA POLTRONA SMALL - BASE LEGNO",
    id: "8895N",
    code: "A8V0W86HJJ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "KAGRTTN2ZT",
  },
  {
    name: "ALA POLTRONA SMALL - BASE METALLO",
    id: "8890N",
    code: "ZHLBPGS4LZ",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "IUXAX6VS07",
  },
  {
    name: "ALA POLTRONA SMALL CAPITONNE\u0027 - BASE LEGNO",
    id: "8909N",
    code: "T1KN7NQPVH",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "P7BJSCMTD1",
  },
  {
    name: "ALA POLTRONA SMALL CAPITONNE\u0027 - BASE METALLO",
    id: "8904N",
    code: "2OJPMKGAGX",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "SQKWIJRN2L",
  },
  {
    name: "ALA POLTRONA SMALL CON BRACCIOLI - BASE LEGNO",
    id: "8896N",
    code: "SCILD8IJB0",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "L2PD4NW8Q6",
  },
  {
    name: "ALA POLTRONA SMALL CON BRACCIOLI - BASE METALLO",
    id: "8891N",
    code: "OLVLWHRLW7",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "DTNJODQ9JY",
  },
  {
    name: "ALA POUFF - BASE LEGNO",
    id: "8888N",
    code: "C389O2MI1G",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "D14FSX3VT0",
  },
  {
    name: "ALA POUFF - BASE METALLO",
    id: "8878N",
    code: "J6H06L1508",
    family: "Ala",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL AVORIO", "METAL PITCH BLACK", "METAL PRUGNA"],
    projectCode: "BX70F63DFE",
  },
  {
    name: "ANYTIME POLTRONA",
    id: "1224NN",
    code: "EMYRI1B4XQ",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FF6FQC0RBD",
  },
  {
    name: "ANYTIME DIVANO 2 POSTI",
    id: "1225NN",
    code: "R1PNLXYVGG",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "2FG5JSDPZF",
  },
  {
    name: "ANYTIME DIVANO 2 POSTI MAJOR",
    id: "1226NN",
    code: "2ZX8SXRNML",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "4N4WLKNXHH",
  },
  {
    name: "ANYTIME DIVANO 3 POSTI",
    id: "1227NN",
    code: "BE62HVRLZ2",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FUKIGATIE2",
  },
  {
    name: "ANYTIME POUFF 90x60",
    id: "1502NN",
    code: "G7Q0VHGRMG",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "OFXCLGHBOQ",
  },
  {
    name: "ANYTIME POUFF 180x60",
    id: "1503NN",
    code: "Q1GBLL8DTA",
    family: "Anytime",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "2MIRUZQYXF",
  },
  {
    name: "APPER DIVANO 3P",
    id: "1461",
    code: "627ARN6T8R",
    hasFabric: true,
    customCollection: [
      "Stretchy",
      "Main Line Flax",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
      "Arda",
    ],
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    hasWood: true,
    family: "Apper",
    projectCode: "J65W9X4395",
  },
  {
    name: "APPER POLTRONA",
    id: "1460",
    code: "WS60HI4ATT",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Main Line Flax",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
      "Arda",
    ],
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    family: "Apper",
    projectCode: "1X2CXF8QE0",
  },
  {
    name: "ARIA POLTRONA",
    id: "8780",
    code: "WU4V0XXVNY",
    hasFabric: true,
    hasWood: true,
    customCollection: ["Saddle Leather"],
    customMaterial: ["METAL RAL 9005"],
    family: "Aria",
    projectCode: "QYKFDMH3O0",
  },
  {
    name: "BENNETT POLTRONCINA CON BASE GIREVOLE",
    id: "8785N",
    code: "7UP2U0UZFL",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    family: "Bennet",
    projectCode: "IYSWTVP7LN",
  },
  {
    name: "BENNETT POLTRONCINA CON GAMBE",
    id: "8760N",
    code: "YF5SVWJM36",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    family: "Bennet",
    projectCode: "G4IKPI8EE0",
  },
  {
    name: "BERNARD POLTRONA CORNER SMALL",
    id: "1120",
    code: "WU2B0LFW5N",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "RF0TAL1NTV",
  },
  {
    name: "BERNARD POLTRONA CENTRALE SMALL",
    id: "1121",
    code: "5VXSK2B2HR",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "0E3QKPO9MU",
  },
  {
    name: "BERNARD POLTRONA SMALL",
    id: "1122",
    code: "97VFWH25ST",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "MAP78FUJYJ",
  },
  {
    name: "BERNARD POUF MEDIUM",
    id: "1125",
    code: "LR4M3JDB26",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "0ZSQTYY2S1",
  },
  {
    name: "BERNARD POLTRONA",
    id: "1130",
    code: "IELL1C3OCN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "8DBT0FTCE6",
  },
  {
    name: "BERNARD POLTRONA CENTRALE",
    id: "1131",
    code: "85KIKLVAHU",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "J3VNDWNMSI",
  },
  {
    name: "BERNARD DIVANO 2 POSTI",
    id: "1132",
    code: "NF3EGCMOHC",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "SMDBJHG2L2",
  },
  {
    name: "BERNARD DIVANO 3 POSTI",
    id: "1133",
    code: "T3LA92IGSN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "PGLAQWZVHG",
  },
  {
    name: "BERNARD DORMEUSE",
    id: "1134",
    code: "WKKB4APGOG",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "YYJWY8YD66",
  },
  {
    name: "BERNARD POUF LARGE",
    id: "1135",
    code: "PU2Q5GIQEN",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "BM4CO8J90R",
  },
  {
    name: "BERNARD PANCA",
    id: "1136",
    code: "CTD9CTZWVB",
    family: "Bernard",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "N75ZOLLC5D",
  },
  {
    name: "BERNARD TAVOLINO MEDIUM 61X61",
    id: "1141",
    code: "7DQLPLZZF7",
    family: "Bernard",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO MARQUINA",
      "MARMO CALACATTA",
      "MARMO CARRARA",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "9GT2EOUL63",
  },
  {
    name: "BERNARD TAVOLINO SMALL 35X35",
    id: "1140",
    code: "D8J5E7RTXJ",
    family: "Bernard",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO MARQUINA",
      "MARMO CALACATTA",
      "MARMO CARRARA",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "UWX76Z81PT",
  },
  {
    name: "BERNARD POUF SMALL",
    id: "1124",
    code: "G6SKERT9BQ",
    family: "Bernard",
    hasFabric: false,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "MO4XZOPXHP",
  },
  {
    name: "BORGO TAVOLINO 40 X 40 X H.43",
    id: "1430",
    code: "76LNXOV7LP",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "GGOFXOM8CS",
  },
  {
    name: "BORGO TAVOLINO 50 X 50 X H.35",
    id: "1431",
    code: "CN5FOD4PZ6",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "8SMEQSC3JU",
  },
  {
    name: "BORGO TAVOLINO 60 X 35 X H.50",
    id: "1432",
    code: "Q40N3CL7PA",
    family: "Borgo",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "QFSORUOLXW",
  },
  {
    name: "COUCHETTE POLTRONA",
    id: "1170",
    code: "UI357RKW7W",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "VCNKDV806V",
  },
  {
    name: "COUCHETTE DIVANO 2 POSTI",
    id: "1171",
    code: "E3E2G1ELJV",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "UYNL0K1LYF",
  },
  {
    name: "COUCHETTE DIVANO 3 POSTI",
    id: "1172",
    code: "A9HL5LIX37",
    family: "Couchette",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 9005", "METAL PITCH BLACK", "METAL RAL 9016"],
    projectCode: "HYGMHTGFT9",
  },
  {
    name: "CUBUS DIVANO 2 POSTI",
    id: "2102NN",
    code: "TJH4JNO635",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "4KATX9L7WM",
  },
  {
    name: "CUBUS DIVANO 3 POSTI",
    id: "2103NN",
    code: "C4NAQAASUU",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "AKT26JPA4G",
  },
  {
    name: "CUBUS POLTRONA",
    id: "2101NN",
    code: "30DTH0M6FZ",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "04Y8GEXKU1",
  },
  {
    name: "CUBUS POLTRONA CON RUOTE",
    id: "2104NN",
    code: "H3JCXL2221",
    family: "Cubus",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FAGGIO TINTA NOCE", "CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "3J86FPT1N4",
  },
  {
    name: "DEP DIVANETTO",
    id: "8402",
    code: "RZS850R4DR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "M2LAMX834P",
  },
  {
    name: "DEP DIVANO 2 POSTI cm. 160",
    id: "8421",
    code: "R42HJOVVUR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "9DKWHSHX6S",
  },
  {
    name: "DEP DIVANO 2 POSTI MAJOR cm. 190",
    id: "8422",
    code: "5R8J5P57DH",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "CON5TZZVQC",
  },
  {
    name: "DEP DIVANO 3 POSTI cm. 220",
    id: "8423",
    code: "XT6HWZX4OR",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "1ZZ3Y0XP9U",
  },
  {
    name: "DEP DIVANO 3 POSTI MAJOR cm. 250",
    id: "8424",
    code: "8DM8T5NKX7",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "K0AB7DSUA1",
  },
  {
    name: "DEP POLTRONA",
    id: "8410",
    code: "WR3994B9Z1",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "KYG16XHJ53",
  },
  {
    name: "DEP POLTRONA CON BASE GIREVOLE",
    id: "8425",
    code: "OSSNXE0181",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "FK7Y4M72EG",
  },
  {
    name: "DEP POLTRONA PICCOLA",
    id: "8401",
    code: "3TMUSGZ64O",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "GVVEQTWQOL",
  },
  {
    name: "DEP POLTRONA PICCOLA CON BASE GIREVOLE",
    id: "8403",
    code: "NDXOLVEAK2",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINO OPACO"],
    family: "Dep",
    projectCode: "LO4IZRX86R",
  },
  {
    name: "DOROTHEA SOFA",
    code: "VA78WX6Y02",
    hasFabric: true,
    hasWood: true,
    family: "Dorothea",
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA SOFA PAR 16",
    code: "PDGKLZUFY0",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR01",
    id: "DOR01",
    code: "9HKUZUKAW0",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "CYZAWHDIL9",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR02",
    id: "DOR02",
    code: "MR0TDKNEAT",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "GVELW5XPO0",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR03",
    id: "DOR03",
    code: "N5DFQZJS4Z",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "ASGAQJC1XD",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR04",
    id: "DOR04",
    code: "VQ34NVNIOW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "VMOYRDNBDQ",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR05",
    id: "DOR05",
    code: "N2S7KFSKHF",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "VB4DYRWRYA",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR06",
    id: "DOR06",
    code: "M32MWSG9QJ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "MRUI8IRKQV",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR07",
    id: "DOR07",
    code: "R0HRXA5WXT",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "TG74GZC862",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR08",
    id: "DOR08",
    code: "WQZSV48K9V",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "DWCCC638B8",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR09",
    id: "DOR09",
    code: "ZQDU0PL9KW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR10",
    id: "DOR10",
    code: "056WENIYTB",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR11",
    id: "DOR11",
    code: "66JTD84BRI",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR12",
    id: "DOR12",
    code: "VCFXPZ9N7X",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR13",
    id: "DOR13",
    code: "6YT3KWQ2XV",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR14",
    id: "DOR14",
    code: "62UC30082Y",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR15",
    id: "DOR15",
    code: "URVHYCWI3U",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR16",
    id: "DOR16",
    code: "NYVEBINREW",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR17",
    id: "DOR17",
    code: "P858TNVYJM",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR18",
    id: "DOR18",
    code: "4EGJ4UZJG3",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR19",
    id: "DOR19",
    code: "W1MVF27VXI",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR20",
    id: "DOR20",
    code: "3UBW6ZKIJQ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "DOROTHEA COMPOSIZIONE DOR21",
    id: "DOR21",
    code: "YXXK0J4ZMZ",
    family: "Dorothea",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "",
  },
  {
    name: "ELLE DIVANO 2P CON PIEDI SENZA CUSCINI",
    id: "8751N",
    code: "LCHAB83ODH",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE DIVANO 2P MAJOR CON PIEDI SENZA CUSCINI",
    id: "8752N",
    code: "NCC7TTG6ZO",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE DIVANO 3P CON PIEDI SENZA CUSCINI",
    id: "8753N",
    code: "8EXQ8WFQW3",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE POLTRONA CON PIEDI SENZA CUSCINOI",
    id: "8750N",
    code: "CKCM5P042B",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE POLTRONCINA CON PIEDI SENZA CUSCINO",
    id: "8790N",
    code: "PLCO3HT0D1",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE DIVANO 2P CON PIEDI INCLUSO 2 CUSCINI",
    id: "8756N",
    code: "2RUIRGE77M",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE DIVANO 2P MAJOR CON PIEDI INCLUSO 2 CUSCINI",
    id: "8757N",
    code: "DAEZK0VLZ9",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "1N90QT9P6I",
  },
  {
    name: "ELLE DIVANO 3P CON PIEDI INCLUSO 3 CUSCINI",
    id: "8758N",
    code: "PFQRO0SLTJ",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE POLTRONA CON PIEDI INCLUSO 1 CUSCINO",
    id: "8755N",
    code: "5VEUZEGK0M",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "ELLE POLTRONCINA CON PIEDI INCLUSO 1 CUSCINO",
    id: "8791N",
    code: "HGI8C8P2B3",
    hasFabric: true,
    hasWood: false,
    family: "Elle P",
    projectCode: "",
  },
  {
    name: "FOLIES BERGER POLTRONA",
    id: "2201N",
    code: "RRRGYVZR97",
    family: "Folies Berger",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "",
  },
  {
    name: "FOLIES MIDI DIVANO 2 POSTI",
    id: "2205NN",
    code: "H95QEWFIJE",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "FOLIES MIDI DIVANO 3 POSTI",
    id: "2207NN",
    code: "N78Z9650LB",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "FOLIES MIDI POLTRONA CON BASE GIREVOLE",
    id: "2204N",
    code: "FSVV6B7YT3",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "NKNPN0M00I",
  },
  {
    name: "FOLIES MIDI POLTRONA CON GAMBE",
    id: "2203NN",
    code: "DZ8TOITYB7",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "157GLPJVGE",
  },
  {
    name: "FOLIES MIDI POUFF FINO A TERRA",
    id: "2206N",
    code: "YCDCM0Z6PU",
    family: "Folies",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "3SYI739SZ9",
  },
  {
    name: "GUEST POLTRONA",
    code: "FUYMAAGCR5",
    id: "9000",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "RZ2PLRI8QR",
  },
  {
    name: "GUEST DIVANO 2 POSTI MAJOR",
    code: "2W5LQHY3OS",
    id: "9002",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "5NVD53JREH",
  },
  {
    name: "GUEST DIVANO 2 POSTI",
    code: "GLKJK0CISW",
    id: "9001",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "60V4M2DVSG",
  },
  {
    name: "GUEST DIVANO 3 POSTI",
    code: "H1W8Y1KLFE",
    id: "9003",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "K7OJE0DSP1",
  },
  {
    name: "GUEST PENISOLA DESTRA",
    code: "SWJ2X6MADA",
    id: "9004",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "0BVXEDWI6Z",
  },
  {
    name: "GUEST PENISOLA SINISTRA",
    code: "UECYH8XVQA",
    id: "9005",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "749PIS1N0R",
  },
  {
    name: "GUEST PENISOLA SMALL DESTRA",
    code: "21R91WT18K",
    id: "9025",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "I0R2TVZKPR",
  },
  {
    name: "GUEST PENISOLA SMALL SINISTRA",
    code: "PNTMQKSQYP",
    id: "9026",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "RDRZBA864J",
  },
  {
    name: "GUEST POUF DIAM. 100",
    code: "HMOYHE4M55",
    id: "9020",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "6FY7ZP710I",
  },
  {
    name: "GUEST POUF DIAM. 130",
    code: "O9M7XUCJEO",
    id: "9021",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "CSH2TL7YBI",
  },
  {
    name: "GUEST POUF DIAM. 70",
    code: "X5EXVRVHF5",
    id: "9019",
    family: "Guest",
    hasFabric: true,
    hasWood: false,
    projectCode: "X54Y8ODDRL",
  },
  {
    name: "HOLIDAY POLTRONA",
    code: "4N8H7GPL13",
    id: "2017N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "T3P3NROUAD",
  },
  {
    name: "HOLIDAY POLTRONA",
    code: "JX6WLXIG4C",
    id: "2017/FSCN",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "M07ZTITGD1",
  },
  {
    name: "HOLIDAY DIVANO 2 POSTI",
    code: "7B7WUTVP9I",
    id: "2018N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "",
  },
  {
    name: "HOLIDAY DIVANO 3 POSTI",
    code: "6F2XASWPX0",
    id: "2019N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "KETZZJWAYG",
  },
  {
    name: "HOLIDAY POLTRONCINA",
    code: "7CWCYRG9IZ",
    id: "2015N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "9CGRRRAZYN",
  },
  {
    name: "HOLIDAY POLTRONCINA CON RUOTE",
    code: "OX6KFJMOGM",
    id: "2035",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "O3KIPV0S7K",
  },
  {
    name: "HOLIDAY POLTRONA ALTA",
    code: "GJ95C8933A",
    id: "2020N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "INGGS2AZV2",
  },
  {
    name: "HOLIDAY SGABELLO",
    code: "OQ6VWNSYKB",
    id: "2034N",
    family: "Holiday",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "CROMATO LUCIDO",
      "METAL RAL 9005",
    ],
    projectCode: "QO8DEDPWIW",
  },
  {
    name: "IDDA TAVOLINO 57 X 61 H.38",
    code: "NO9NA7ZQAP",
    id: "1420",
    family: "IDDA",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "V5GOPX6X6M",
  },
  {
    name: "IDDA TAVOLINO 71 X 76 H.28",
    code: "ETDVX2UGB2",
    id: "1421",
    family: "IDDA",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "IBM86WXDEX",
  },
  {
    name: "JANGO DIVANO 2 POSTI CON BASE CROCIERA",
    code: "DYECP6VCA8",
    id: "2031NN",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SRG3U18S87",
  },
  {
    name: "JANGO POLTRONA",
    code: "BK255915CG",
    id: "2030N",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "TIXFMMEVOO",
  },
  {
    name: "JANGO POLTRONA CON BASE CROCIERA",
    code: "9CNF4NB19P",
    id: "3032N",
    family: "Jango",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "WYMC5BI2KA",
  },
  {
    name: "JOIN DIVANO 2 POSTI SMALL cm.140",
    code: "6NAXBWE79N",
    id: "8841N",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "YTPQVP6AN5",
  },
  {
    name: "JOIN POUFF 180x70",
    code: "8MIVISS76Y",
    id: "8848N",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "38O7PV30NR",
  },
  {
    name: "JOIN DIVANO 2 POSTI cm.160",
    id: "8843N",
    code: "FNDSLUI04O",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "S00IYLOOOE",
  },
  {
    name: "JOIN DIVANO 2 POSTI MAJOR cm.185",
    id: "8844N",
    code: "FRCCU3MDG4",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "H9UKPNLOGY",
  },
  {
    name: "JOIN DIVANO 3 POSTI BIG",
    id: "8856N",
    code: "T94UY5I2KF",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "OXCK262NUJ",
  },
  {
    name: "JOIN DIVANO 3 POSTI cm.220",
    id: "8845N",
    code: "JZP5IDBG04",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "LTEHA3MQUU",
  },
  {
    name: "JOIN DIVANO ALTO 2 POSTI BIG",
    id: "8854N",
    code: "TE3PSV80R5",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "UVJIDSC34N",
  },
  {
    name: "JOIN DIVANO ALTO 2 POSTI MAJOR BIG",
    id: "8855N",
    code: "6LYFP7RLZ0",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "S74Q0A5IQO",
  },
  {
    name: "JOIN DIVANO ALTO SMALL",
    id: "8852N",
    code: "IGOVKAKLN9",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "4B3GLRMJ6B",
  },
  {
    name: "JOIN PANCA 140x50",
    id: "8849N",
    code: "LUFH6GGGLT",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "3Q49NMZJT9",
  },
  {
    name: "JOIN PANCA 180x50",
    id: "8850N",
    code: "MJ9CQ78Y8W",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "LX1EGASI2Z",
  },
  {
    name: "JOIN POLTRONA",
    id: "8842N",
    code: "YG5IYBDX9V",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "AG4QL3YIP3",
  },
  {
    name: "JOIN POLTRONA ALTA BIG",
    id: "8853N",
    code: "ZVIQ3NB4A5",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "0I4DECOJ2G",
  },
  {
    name: "JOIN POLTRONA ALTA SMALL",
    id: "8851N",
    code: "11UZH33GEP",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "XNQKDY7V2X",
  },
  {
    name: "JOIN POLTRONA SMALL",
    id: "8840N",
    code: "J88QY9Z9KB",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "W72XFJOWVR",
  },
  {
    name: "JOIN POUFF 140x70",
    id: "8847N",
    code: "TWJNO8UTSJ",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "V8WT8OOA98",
  },
  {
    name: "JOIN POUFF 70x70",
    id: "8846N",
    code: "QVRDBVZGBI",
    hasFabric: true,
    hasWood: true,
    family: "Join",
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "ALLUMINIO LUCIDO",
    ],
    projectCode: "R9TNEA9X33",
  },
  {
    name: "KEISHO TAVOLO",
    code: "1NKFA13B04",
    id: "1110",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "FRASSINO TINTA NOCE",
    ],
    customPiano: [
      "METAL AVORIO",
      "METAL AZZURRO",
      "METAL BRONZO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL OTTONE",
      "METAL PITCH BLACK",
      "METAL RAME",
      "METAL TE VERDE",
    ],
    hasPiano: true,
    family: "Keisho",
    projectCode: "D6YYUMHF78",
  },
  {
    name: "LINE BENCH PANCA 120",
    code: "DPXPIS8TVE",
    id: "8691N",
    family: "Line Bench",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    customPiano: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    projectCode: "2S36MEVL56",
  },
  {
    name: "LINE BENCH PANCA 180",
    code: "E105R84ZN6",
    id: "8692N",
    family: "Line Bench",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    customPiano: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA NOCE",
      "METAL RAL 9016",
      "METAL RAL 3003",
      "METAL RAL 5014",
    ],
    projectCode: "SV3POHHUCF",
  },
  {
    name: "MANHATTAN DIVANO 2 POSTI",
    code: "UF1P5WMGAG",
    id: "5102N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "9QPI73CLMT",
  },
  {
    name: "MANHATTAN DIVANO 3 POSTI",
    code: "YDRU7DR3YN",
    id: "5104N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "QB0F0132WS",
  },
  {
    name: "MANHATTAN DIVANO 2 POSTI MAJOR",
    code: "AZXUCBHLYZ",
    id: "5103N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "B1IVOBVQX6",
  },
  {
    name: "MANHATTAN DIVANO 3 POSTI MAJOR",
    code: "01TOJ2RPF4",
    id: "5105N",
    family: "Manhattan",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: [
      "FAGGIO NATURALE",
      "FAGGIO TINTA NOCE",
      "ALLUMINIO LUCIDO",
      "ALLUMINO OPACO",
    ],
    projectCode: "2NNS5RKSUF",
  },
  {
    name: "MATRIX DIVANO 2 POSTI",
    code: "Y9JD91ZH9Z",
    id: "4102N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "GW9H3005LD",
  },
  {
    name: "MATRIX DIVANO 2 POSTI MAJOR",
    code: "D9LKH8JAT8",
    id: "4103N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "79F3EEITLE",
  },
  {
    name: "MATRIX DIVANO 3 POSTI",
    code: "NHBO95YQZF",
    id: "4104N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "GPMTR6HEQX",
  },
  {
    name: "MATRIX POLTRONA",
    code: "CE6D69KPS9",
    id: "4101N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "2G1H2XD2XG",
  },
  {
    name: "MATRIX POLTRONCINA TRENDY CON BASE",
    code: "RW4UOIXMAW",
    id: "4202N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "B9GC2TIPGU",
  },
  {
    name: "MATRIX POLTRONCINA TRENDY GIREVOLE",
    code: "A6GFRKA923",
    id: "4201N",
    family: "Matrix",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "PWHUCGDA1G",
  },
  {
    name: "MIL TAVOLO DIAMETRO 100",
    code: "BJ0TFF5LY8",
    id: "9097",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "ICQLFLEVC0",
  },
  {
    name: "MIL TAVOLO DIAMETRO 50",
    code: "A1BN2N6UJ0",
    id: "9095",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "PPQF66YFG4",
  },
  {
    name: "MIL TAVOLO DIAMETRO 75",
    code: "EOCJBJQJX6",
    id: "9096",
    family: "Mil",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "ROVERE SPAZZOLATO BIANCO",
    ],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "VETRO TRASPARENTE",
      "VETRO FUME",
      "ROVERE SPAZZOLATO BIANCO",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "70BZ10M1GM",
  },
  {
    name: "MODO POUFF GRANDE",
    code: "51RJR3VEHP",
    id: "8560",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 7021"],
    family: "Modo",
    projectCode: "XH7GMM7OIV",
  },
  {
    name: "MODO POUFF PICCOLO",
    code: "DKTBCO4RK3",
    id: "8551",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL RAL 7021"],
    family: "Modo",
    projectCode: "USF6GZ3J1C",
  },
  {
    name: "MOLECULE PANCA",
    code: "O6R5KQFQ8L",
    id: "5605",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "T8B011H6CU",
  },
  {
    name: "MOLECULE POUFF RETTANGOLO",
    code: "VCKVFWQCVP",
    id: "5608",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "GDX0YKU1CT",
  },
  {
    name: "MOLECULE TAVOLO",
    code: "NIUHX6OM4B",
    id: "5606N",
    family: "Molecule",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "XID6IB8WM2",
  },
  {
    name: "MOLECULE POUFF INSERTO",
    code: "O09VCQ92EP",
    id: "5604",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "AJ0F3TQ3S9",
  },
  {
    name: "MOLECULE POUFF QUADRO",
    code: "SXUN8CYNHT",
    id: "5607",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "HZMJ07UPB7",
  },
  {
    name: "MOLECULE POUFF TONDO",
    code: "09YAMJY0IZ",
    id: "5603",
    family: "Molecule",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    customPiano: ["WOOD RAL 9005", "WOOD RAL 9016"],
    projectCode: "ANZ5PV9FIS",
  },
  {
    name: "MON COEUR DIVANO",
    code: "741CNX3PLN",
    id: "3002N",
    family: "Mon Coeur",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    projectCode: "ELZG0G2PNI",
  },
  {
    name: "MON COEUR POLTRONA",
    code: "CGYMRP618W",
    id: "3001N",
    family: "Mon Coeur",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["METAL RAL 9005", "METAL RAL 9016", "CROMATO LUCIDO"],
    projectCode: "Z5EHUF4Y3B",
  },
  {
    name: "MON PETIT COEUR POLTRONA",
    code: "UGKWJ3TXO9",
    id: "3100N",
    family: "Mon Petit",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9KTH1NPU63",
  },
  {
    name: "MRS.BUFFY LARGE DIVANO 2 POSTI",
    code: "56AGTJ396Z",
    id: "8106N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "5IMD6UKXJZ",
  },
  {
    name: "MRS.BUFFY POLTRONA",
    code: "WESIF4JIS5",
    id: "8105N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "P5MJO9MRAQ",
  },
  {
    name: "MRS.BUFFY DIVANO 3 POSTI",
    code: "HXKFI9KXEW",
    id: "8108N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "G93KCYWXYA",
  },
  {
    name: "MRS.BUFFY EASY POLTRONA",
    code: "5CL0DBG3XR",
    id: "8101N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "S148WTSUHA",
  },
  {
    name: "MRS.BUFFY LARGE DIVANO 2 POSTI MAJOR",
    code: "HRB6KJCWLA",
    id: "8107N",
    family: "Mrs. Buffy",
    hasFabric: true,
    hasWood: true,
    hasPiano: false,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "OICJG0HIO5",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    family: "Myplace",
    id: "9050N",
    code: "7EW7MIKME5",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "O0JS7T7756",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    family: "Myplace",
    id: "9054N",
    code: "QCYB5Y94XA",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "BI0O202AFC",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    family: "Myplace",
    id: "9061N",
    code: "4RXJLY82OA",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "7M2D758PV8",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9062N",
    code: "CP0LGETDB6",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "K8V0GZRXLZ",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9063N",
    code: "DPNKXYVHOU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "9N838CM4NW",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9064N",
    code: "586PSWIH82",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "5CTAHQ16OK",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9065N",
    code: "JOS2OLYBVB",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "8IAGSCPJL5",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9066N",
    code: "X9A4XTGPCU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "W7K0R29LBE",
  },
  {
    name: "MYPLACE SEDIA LOUNGE CON SLITTA",
    id: "9067N",
    code: "01WPFDMFOC",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL PITCH BLACK",
      "METAL AVORIO",
    ],
    projectCode: "RX66RL4V82",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    id: "9051N",
    code: "5AY4ASHMF3",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "YZ9ZLM0P5I",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    id: "9052N",
    code: "ZA847NMQG9",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "XCUCNB565P",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    id: "9053N",
    code: "30RVXOG8XI",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "4TK5QCHUK0",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE LEGNO",
    id: "9055N",
    code: "D9NHU4FN66",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "APXTH185PG",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    id: "9056N",
    code: "JNVT0B46RE",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "ZWHMEANCC4",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    id: "9057N",
    code: "J843BSIAGT",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "0Z1G62D4GV",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    id: "9058N",
    code: "C05EKJ3G5D",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "3KDBVZ1M9U",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    id: "9059N",
    code: "TE7HES9V57",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "GL3RZE6SO8",
  },
  {
    name: "MYPLACE SEDIA LOUNGE GIREVOLE CON BASE METALLO",
    id: "9060N",
    code: "TKEBA5E8BU",
    family: "Myplace",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    projectCode: "B7K9W8HWVK",
  },
  {
    name: "NAP DIVANO 2 POSTI",
    family: "Nap",
    id: "1071N",
    code: "CTJUEDJYP8",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "I8OGC75CCD",
  },
  {
    name: "NAP DIVANO 3 POSTI CON CUSCINI",
    family: "Nap",
    id: "1092N",
    code: "QZYG2B09L3",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "JRRHCPLCTW",
  },
  {
    name: "NAP DIVANO 4 POSTI",
    family: "Nap",
    id: "1074N",
    code: "SSF9ISHRUH",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "CWL07K5581",
  },
  {
    name: "NAP DIVANO 2 POSTI CON CUSCINI",
    family: "Nap",
    id: "1091N",
    code: "KNL91M3030",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "NXAG7J9SG9",
  },
  {
    name: "NAP DIVANO 3 POSTI",
    family: "Nap",
    id: "1072N",
    code: "UJ6MJBBOSK",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "J26BO95SX4",
  },
  {
    name: "NAP DIVANO 3 POSTI MAJOR",
    family: "Nap",
    id: "1073N",
    code: "0MVM0GXTSX",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "9HG9NOJ446",
  },
  {
    name: "NAP DIVANO 3 POSTI MAJOR CON CUSCINI",
    family: "Nap",
    id: "1093N",
    code: "EFYL06OB4A",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "UZP7HDNA7B",
  },
  {
    name: "NAP DIVANO 4 POSTI CON CUSCINI",
    family: "Nap",
    id: "1094N",
    code: "KZ21PQCO51",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "5YX7ROCNAO",
  },
  {
    name: "NAP POLTRONA",
    family: "Nap",
    id: "1070N",
    code: "AJYO4UKCR8",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    projectCode: "H5YD8RPN24",
  },
  {
    name: "NAUTILE POLTRONA RIVESTITA",
    code: "NP7OR4WQQY",
    id: "8702",
    hasFabric: true,
    hasWood: false,
    family: "Nautile",
    projectCode: "KDMP47JIV5",
  },
  {
    name: "NEWTIME ANGOLO",
    code: "T6D5ACRJRO",
    id: "1445",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "8P006OOHWQ",
  },
  {
    name: "NEWTIME DIVANO 2 POSTI",
    code: "817E5R6H8K",
    id: "1441",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "3TRJIF0APY",
  },
  {
    name: "NEWTIME DIVANO 3 POSTI",
    code: "SF4C7LBGV1",
    id: "1442",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "LSG0PNY9FM",
  },
  {
    name: "NEWTIME POLTRONA",
    code: "NWXEJXR7MO",
    id: "1440",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "EP53HD3UEN",
  },
  {
    name: "NEWTIME POUFF 140X90",
    code: "7LFY4YEZYG",
    id: "1449",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "DBW8CO0RBP",
  },
  {
    name: "NEWTIME POUFF 210X90",
    code: "25PC320TNL",
    id: "1450",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "A16LWKZ58M",
  },
  {
    name: "NEWTIME POUFF 70X90",
    code: "QGYB2MGU6U",
    id: "1448",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "NWC709TRIV",
  },
  {
    name: "NEWTIME SOFA",
    code: "F64LZMALPH",
    hasFabric: true,
    hasWood: true,
    family: "Newtime",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "",
  },
  {
    name: "NODE PLUS SOFA",
    code: "SEFV479SM5",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["FRASSINO NATURALE", "FRASSINO TINTA GRAFITE"],
    family: "Node Plus",
    projectCode: "",
  },
  {
    name: "OSAKA SOFA",
    code: "ENUQUNXR4G",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "",
  },
  {
    name: "OSAKA DIVANO 180",
    code: "F5LZBQUIQA",
    id: "8801",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "A7JOB02BFJ",
  },
  {
    name: "OSAKA DIVANO 230",
    code: "THWSW9VF11",
    id: "8802",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "NXJVNH0V1W",
  },
  {
    name: "OSAKA DIVANO 280",
    code: "JP2MN103EV",
    id: "8803",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "EQKGH2UK6C",
  },
  {
    name: "OSAKA DIVANO 330",
    code: "J7FKPL1D30",
    id: "8804",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "K4T36O31IG",
  },
  {
    name: "OSAKA DIVANO 380",
    code: "8QW173RUHF",
    id: "8805",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "M47XGSMDE6",
  },
  {
    name: "OSAKA DIVANO 430",
    code: "QET852VVBK",
    id: "8806",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "JLLJUZ7U7Y",
  },
  {
    name: "OSAKA DIVANO 480",
    code: "C79XYBFCC3",
    id: "8807",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "CN6OSLAOWV",
  },
  {
    name: "OSAKA DIVANO 530",
    code: "MQVTJSH3TZ",
    id: "8808",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "GK1OLXN7SY",
  },
  {
    name: "OSAKA DIVANO 580",
    code: "ZUZJO2P4FY",
    id: "8809",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "J7A4GJ8ZYN",
  },
  {
    name: "OSAKA DIVANO 630",
    code: "J8ASARI4RR",
    id: "8810",
    hasFabric: true,
    hasWood: true,
    customCollection: [
      "Stretchy",
      "Bit",
      "Mosaic 2",
      "Uniform Mèlange",
      "Triangle",
      "Gentle 2",
    ],
    customMaterial: ["METAL RAL 9005"],
    family: "Osaka",
    projectCode: "RXVXQ8997B",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 110 H.25 INCLINATO",
    id: "8836",
    code: "JX6WOQJY99",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "0YRG1E1SJG",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 35 H.40",
    id: "8830",
    code: "8NX9JMDJGF",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "1OR32QSTAC",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 35 H.40 INCLINATO",
    id: "8832",
    code: "DPY4P3ZBFF",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "9TCSS72VWD",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 35 H.45",
    id: "8831",
    code: "7UHYX6NGRC",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "N2TR9AV7UZ",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 35 H.50 INCLINATO",
    id: "8833",
    code: "URDE9DHAI9",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "PSBS515G4P",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 50 H.30 INCLINATO",
    id: "8834",
    code: "I9OK6AM3WJ",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "E6G9K44R0Z",
  },
  {
    name: "SCANCARO TAVOLINO DIAMETRO 80 H.25 INCLINATO",
    id: "8835",
    code: "ZV7VXTR7HY",
    family: "Scancaro",
    hasFabric: false,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
      "METAL CIOCCOLATO",
      "METAL AZZURRO FIORDALISO",
      "METAL TE VERDE",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "2LU5IKJ1GT",
  },
  {
    name: "PHAR LAP SEDIA LOUNGE",
    code: "9TJXJS3710",
    id: "1160",
    hasFabric: true,
    family: "Phar Lap",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "4JKV5HGMN1",
  },
  {
    name: "PINCH SOFA",
    code: "D2KVHLEDBI",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "",
  },
  {
    name: "PINCH DIVANO 2 POSTI H.140",
    code: "BWTK4PDRR2",
    id: "9089",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "30ZUEEPFYS",
  },
  {
    name: "PINCH DIVANO 2 POSTI H.70",
    code: "EDQC1AVFQP",
    id: "9081",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "RL59AB0PMW",
  },
  {
    name: "PINCH DIVANO 2 POSTI H.90",
    code: "IB4K5SDI6F",
    id: "9085",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "PG7C3YCTEF",
  },
  {
    name: "PINCH DIVANO 3 POSTI H.140",
    code: "5ZAIS15G4S",
    id: "9090",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "FSOTSX7POR",
  },
  {
    name: "PINCH DIVANO 3 POSTI H.70",
    code: "C08HW4XP3E",
    id: "9082",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "4Q4WCCQO93",
  },
  {
    name: "PINCH DIVANO 3 POSTI H.90",
    code: "R0M6EYA8UV",
    id: "9086",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "QHBMYZFJLM",
  },
  {
    name: "PINCH DIVANO 3 POSTI MAJOR H.140",
    code: "B45NSYW4M0",
    id: "9091",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "K8KND8NGIU",
  },
  {
    name: "PINCH DIVANO 3 POSTI MAJOR H.70",
    code: "RVL14C0LHM",
    id: "9083",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "QRJXBDRJUA",
  },
  {
    name: "PINCH DIVANO 3 POSTI MAJOR H.90",
    code: "SKRKKI19AW",
    id: "9087",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "N3HEV5F614",
  },
  {
    name: "PINCH DIVANO SLIM 2 POSTI H.140",
    code: "QH9HVJFU11",
    id: "9101",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "OM5TGFO0WR",
  },
  {
    name: "PINCH DIVANO SLIM 3 POSTI H.140",
    code: "L3FET1I8LO",
    id: "9102",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "NDQM7MKAI9",
  },
  {
    name: "PINCH DIVANO SLIM 3 POSTI MAJOR H.140",
    code: "6ZDO8VPQXU",
    id: "9103",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "B6LF02XQFZ",
  },
  {
    name: "PINCH POLTRONA H.140",
    code: "WPANLVHQ4W",
    id: "9088",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "UYK0797PTY",
  },
  {
    name: "PINCH POLTRONA H.70",
    code: "ES76O3ATNA",
    id: "9080",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "WUWDPAXFJA",
  },
  {
    name: "PINCH POLTRONA H.90",
    code: "XO8N5FBJGK",
    id: "9084",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "D52ETRUBGY",
  },
  {
    name: "PINCH POLTRONA SLIM H.140",
    code: "FRDUCBPUIP",
    id: "9100",
    hasFabric: true,
    hasWood: true,
    family: "Pinch",
    customMaterial: ["METAL AVORIO", "METAL PRUGNA", "METAL PITCH BLACK"],
    projectCode: "CZBC9VEASG",
  },
  {
    name: "PLOFF POUFF DIAMETRO 110",
    code: "TJCYBHVGHV",
    id: "8762_D110_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "EI9V3R4DCZ",
  },
  {
    name: "PLOFF POUFF DIAMETRO 70",
    code: "C5D3CYFT39",
    id: "8760_D70_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "9SDTAHIS1C",
  },
  {
    name: "PLOFF POUFF DIAMETRO 90",
    code: "5CXGEVTQMN",
    id: "8761_D90_POUFF",
    hasFabric: false,
    hasWood: false,
    family: "Ploff",
    projectCode: "DQ0V872VPK",
  },
  {
    name: "PUZZLE DIVANO",
    code: "ML610RF36S",
    hasFabric: true,
    hasWood: false,
    family: "Puzzle",
    projectCode: "",
  },
  {
    name: "PUZZLE DIVANO 2 POSTI BIG",
    code: "CD02A5XMA0",
    id: "5205",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "EY4WVFNT6A",
  },
  {
    name: "PUZZLE DIVANO 2 POSTI MAJOR BIG",
    code: "C2OVVIPNPD",
    id: "5207",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "T8UDNC7QE1",
  },
  {
    name: "PUZZLE DIVANO 2 POSTI MAJOR SMALL",
    code: "S1VOMUPQQP",
    id: "5206",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "4K3EAHKWPW",
  },
  {
    name: "PUZZLE DIVANO 2 POSTI SMALL",
    code: "OO73WPSM2I",
    id: "5204",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "5JUEUEO3VK",
  },
  {
    name: "PUZZLE DIVANO 3 POSTI BIG",
    code: "UGND4WYJ4S",
    id: "5209",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "Z749F79B62",
  },
  {
    name: "PUZZLE DIVANO 3 POSTI SMALL",
    code: "WNRSGDWIII",
    id: "5208",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "7QP17QY6QD",
  },
  {
    name: "PUZZLE DORMEUSE BIG DX",
    code: "G0SXCR8J06",
    id: "5223",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "1OZPIOGP0V",
  },
  {
    name: "PUZZLE DORMEUSE BIG SX",
    code: "R06904RURI",
    id: "5225",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "F2RQ5NE90G",
  },
  {
    name: "PUZZLE DORMEUSE SMALL DX",
    code: "8QP8STORUL",
    id: "5222",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "ZUHXJGJFZ2",
  },
  {
    name: "PUZZLE DORMEUSE SMALL SX",
    code: "C9U0VCXEOZ",
    id: "5224",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "KV2BWURLWG",
  },
  {
    name: "PUZZLE POLTRONA SMALL",
    code: "0JWCI6DOMG",
    id: "5202",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "SEI9HOU571",
  },
  {
    name: "PUZZLE POLTRONA BIG",
    code: "FZGIOIE0AG",
    id: "5203",
    family: "Puzzle",
    hasFabric: true,
    hasWood: false,
    projectCode: "K9CWZDI450",
  },
  {
    name: "SERIE 50 POLTRONA",
    code: "XT4G1FBA1L",
    hasFabric: true,
    family: "Serie 50",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "",
  },
  {
    name: "SERIE 50/A POLTRONA ALTA",
    code: "039Q08CJ3R",
    id: "8710/AN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "2E9D2RIS4V",
  },
  {
    name: "SERIE 50/A POLTRONA ALTA LARGE",
    code: "JMPVNL74LE",
    id: "8711/AN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "JK1VUMN0AD",
  },
  {
    name: "SERIE 50/B POLTRONA BASSA",
    code: "YZO7S5UJ9A",
    id: "8713/BN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "O58M4STF8X",
  },
  {
    name: "SERIE 50/C DIVANO 2 POSTI",
    code: "KI6OEU9WU1",
    id: "8716/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "OQOY4J6J0I",
  },
  {
    name: "SERIE 50/C DIVANO 2 POSTI MAJOR",
    code: "Y0HSR5W619",
    id: "8717/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "GQ5648IC35",
  },
  {
    name: "SERIE 50/C DIVANO 3 POSTI",
    code: "TL8KUEC35C",
    id: "8718/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "RWEXWE0VFS",
  },
  {
    name: "SERIE 50/C POLTRONA",
    code: "KKS4AH2UCT",
    id: "8715/CN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "GMP8QSCMV8",
  },
  {
    name: "SERIE 50/D SEDIA LOUNGE",
    code: "FXIOJ9LG60",
    id: "8722/DN",
    family: "Serie 50",
    hasFabric: true,
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA WENGE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 7021",
    ],
    projectCode: "LFSW7FTPBW",
  },
  {
    name: "SHES SGABELLO",
    code: "MGSCUT1TEA",
    id: "8775N",
    hasFabric: true,
    family: "Shes",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9QXBZQX8HI",
  },
  {
    name: "SOAVE DIVANO 3 POSTI CON BRACCIOLI",
    code: "3S3GU3RO7J",
    id: "1035N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "UXNCFJAAEU",
  },
  {
    name: "SOAVE DIVANO 3 POSTI",
    code: "9W95VDW25D",
    id: "1034N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "NXHN3Q564T",
  },
  {
    name: "SOAVE DIVANO 2 POSTI CON BRACCIOLI",
    code: "Y5X2Q67LQF",
    id: "1033N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "K26GX0M36B",
  },
  {
    name: "SOAVE DIVANO 2 POSTI",
    code: "ACUSDN6TJG",
    id: "1032N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "GFQC4YR90C",
  },
  {
    name: "SOAVE POLTRONA",
    code: "HMB8QQJFU0",
    id: "1030N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "KHXELCHYHP",
  },
  {
    name: "SOAVE POLTRONA CON BRACCIOLI",
    code: "QJAR72EHWX",
    id: "1031N",
    hasFabric: true,
    family: "Soave",
    hasWood: true,
    customMaterial: [
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL OTTONE",
      "METAL RAME",
    ],
    projectCode: "9B62LA0S5X",
  },
  {
    name: "SOCRATE DIVANO 2 POSTI",
    code: "VPCU0JD4SJ",
    id: "1223NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "B6PK8XRWWP",
  },
  {
    name: "SOCRATE POLTRONA",
    code: "WGXKA7OK2V",
    id: "1222NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FFN969D8V6",
  },
  {
    name: "SOCRATE POUFF 180x60",
    code: "I2FLJY0GBW",
    id: "1253NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SXTK4HLN2X",
  },
  {
    name: "SOCRATE POUFF 90x60",
    code: "SGAFIDN34F",
    id: "1252NN",
    hasFabric: true,
    family: "Socrate",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "QQPOB6BE95",
  },
  {
    name: "SPEAK EASY POLTRONA CON PIEDINI",
    code: "1KEHVF4TV9",
    id: "2234N",
    hasFabric: true,
    family: "Speak Easy",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "ALLUMINIO LUCIDO"],
    projectCode: "X6S4AZVIZB",
  },
  {
    name: "SPEAK EASY POLTRONA GIREVOLE",
    code: "9ELDIHGYC7",
    id: "2233N",
    hasFabric: true,
    family: "Speak Easy",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "ALLUMINIO LUCIDO"],
    projectCode: "UXN6KLY6JM",
  },
  {
    name: "STONES DIVANO 2 POSTI",
    code: "8S2CRQEML9",
    hasFabric: true,
    family: "Stones",
    id: "5703",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Q6TPC7G9MB",
  },
  {
    name: "STONES DIVANO 3 POSTI",
    code: "QPEHAKG41O",
    hasFabric: true,
    family: "Stones",
    id: "5704",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "US44GSPE5M",
  },
  {
    name: "STONES DORMEUSE",
    code: "8TE26OCDAU",
    hasFabric: true,
    family: "Stones",
    id: "5705",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Z0TC4XCND2",
  },
  {
    name: "STONES POLTRONA",
    code: "THQL8PWPH1",
    hasFabric: true,
    family: "Stones",
    id: "5702",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "LCJM3OXJAG",
  },
  {
    name: "STONES SEDIA",
    code: "M0XY7F7QX5",
    hasFabric: true,
    family: "Stones",
    id: "5701",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO"],
    projectCode: "Z1VA0NLHAL",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI01",
    code: "PQIS1COTKI",
    id: "SUI01",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "VMID6VFYC5",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI02",
    code: "QOGXIFX3NB",
    id: "SUI02",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "FCXLHEV3FH",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI03",
    code: "EWWXF3ZJRF",
    id: "SUI03",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "ODD86PEU5G",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI04",
    code: "Q98RBWCOOB",
    id: "SUI04",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "4X70AQQLSZ",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI05",
    code: "6ZIVTFS750",
    id: "SUI05",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "UFGIOHCK75",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI06 DX",
    code: "DNKXGNG4CC",
    id: "SUI06DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AO0HCY15W2",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI06 SX",
    code: "CX51A8CS41",
    id: "SUI06SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "BWT1BZ89YW",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI07 DX",
    code: "3FH8W3QO0L",
    id: "SUI07DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "N2RX0PPXF7",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI07 SX",
    code: "OFC1CSSYET",
    id: "SUI07SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "MD49B4FPTF",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI08 DX",
    code: "GN1GXLMLFL",
    id: "SUI08DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "1AW0NEQOY8",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI08 SX",
    code: "ILFBLY56VE",
    id: "SUI08SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "051YHCDWW6",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI09 DX",
    code: "BL7N375H95",
    id: "SUI09DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "DNKYHCNTZ1",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI09 SX",
    code: "4UFROIQ2KB",
    id: "SUI09SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "P12YOMK80X",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI10 DX",
    code: "MG8C7N4D5P",
    id: "SUI10DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "JE6JRLFBT9",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI10 SX",
    code: "S22Z3U98ES",
    id: "SUI10SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "R479PQP1GH",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI11 DX",
    code: "Y2IHWRLQO1",
    id: "SUI11DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "W79EE2QARK",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI11 SX",
    code: "IC77NY47C4",
    id: "SUI11SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "5HZ1DN8QET",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI12 DX",
    code: "JMG2PQ8U1N",
    id: "SUI12DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "NDFGVNY24G",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI12 SX",
    code: "34PC5ODCMA",
    id: "SUI12SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AD18FMHFSF",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI13 DX",
    code: "AXL3LON3JD",
    id: "SUI13DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "0RAMJM2JB8",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI13 SX",
    code: "6Q4HBBLKW7",
    id: "SUI13SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "NMPG2F1CMW",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI14 DX",
    code: "2EBQF9ZUI7",
    id: "SUI14DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "FZXOPSOWT1",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI14 SX",
    code: "YJ5UUS4UPK",
    id: "SUI14SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "TWA2KQCY7K",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI15 DX",
    code: "98E1Q3MIVM",
    id: "SUI15DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "MIRFP0PRET",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI15 SX",
    code: "2WF4OV6A7B",
    id: "SUI15SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "JZ2NA1JALA",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI16 DX",
    code: "TGOAG2CXRV",
    id: "SUI16DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "U85ZOMUJBO",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI16 SX",
    code: "EK71HKC13F",
    id: "SUI16SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "0GHCOD2213",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI17 DX",
    code: "MKRBYX5WEH",
    id: "SUI17DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "AFHG504QJT",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI17 SX",
    code: "L8KUS8T30G",
    id: "SUI17SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "KRKS1KW7OZ",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI18 DX",
    code: "WYNHZXPQ0U",
    id: "SUI18DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "S9J81MY6IQ",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI18 SX",
    code: "MRVSCV1C5M",
    id: "SUI18SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "LWUT2O57WF",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI19 DX",
    code: "GD4YJD3NWA",
    id: "SUI19DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "D6FZWWMCWM",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI19 SX",
    code: "F2B45CFA06",
    id: "SUI19SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "DRVVYW0HHK",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI20 DX",
    code: "2PN9VJJVZ5",
    id: "SUI20DX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "K7SRSXRHYP",
  },
  {
    name: "SUISEKI COMPOSIZIONE SUI20 SX",
    code: "JNUOTBOL9I",
    id: "SUI20SX",
    hasFabric: true,
    family: "Suiseki",
    hasWood: false,
    projectCode: "562SKIFXEZ",
  },
  {
    name: "SUSHI PANCA 150",
    code: "HQ1S6TIYKN",
    id: "4010N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "17PW8CRNYH",
  },
  {
    name: "SUSHI PANCA 200",
    code: "74IEQA7B8S",
    id: "4011N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "AOC25YQINO",
  },
  {
    name: "SUSHI POUFF 120",
    code: "GB15QWAB2Y",
    id: "4009N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "LJQUBYBWWD",
  },
  {
    name: "SUSHI POUFF 60",
    code: "CW9599BEL0",
    id: "4007N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "I1F2F2J1XC",
  },
  {
    name: "SUSHI POUFF 90",
    code: "AWVO4ZS4US",
    id: "4008N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9ZAO7DVRHN",
  },
  {
    name: "SUSHI POUFF CURVO 188 CHIUSO",
    code: "TIX5MJPVO4",
    id: "4019N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "T1092DJ4LR",
  },
  {
    name: "SUSHI POUFF CURVO 200 APERTO",
    code: "Z8Y6ZTU9CL",
    id: "4021N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "FWSW0MLK1T",
  },
  {
    name: "SUSHI POUFF CURVO 90° APERTO",
    code: "0Z2ENUNVOL",
    id: "4020N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "QZHFMSP50M",
  },
  {
    name: "SUSHI POUFF CURVO 90° CHIUSO",
    code: "BEK1MNHZ3Z",
    id: "4018N",
    hasFabric: true,
    family: "Sushi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "YGE1K89IH7",
  },
  {
    name: "SWALE POLTRONA ALTA BASE RIVESTITA",
    code: "FON517GGJY",
    id: "1152",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "XJFFXA2M4K",
  },
  {
    name: "SWALE POLTRONA ALTA CON PIEDI",
    code: "5A2GCSNIQ9",
    id: "1150",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "2VM8RTQ22J",
  },
  {
    name: "SWALE POLTRONA BASSA BASE RIVESTITA",
    code: "GCUS8SQW7G",
    id: "1153",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "AW5NOXK5NY",
  },
  {
    name: "SWALE POLTRONA BASSA CON PIEDI",
    code: "G6GQ4S2THC",
    id: "1151",
    hasFabric: true,
    family: "Swale",
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL FANGO",
      "METAL PRUGNA",
      "METAL PITCH BLACK",
    ],
    projectCode: "JZ2JVW8CH3",
  },
  {
    name: "TACO SEDIA",
    code: "UGSYZT2GH7",
    id: "1400",
    hasFabric: true,
    hasWood: true,
    customCollection: ["Jaak"],
    customMaterial: ["METAL PITCH BLACK", "METAL AVORIO"],
    family: "Taco",
    projectCode: "FPMBKCWQ26",
  },
  {
    name: "TAILOR DIVANO 2 POSTI - PIEDI METALLO",
    code: "WG153LKCYF",
    id: "9073N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "LJC6LK4N5C",
  },
  {
    name: "TAILOR DIVANO 2 POSTI MAJOR - PIEDI LEGNO",
    code: "HBB7OF0HNY",
    id: "4074N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "I54JWAOXYQ",
  },
  {
    name: "TAILOR DIVANO 2 POSTI MAJOR - PIEDI METALLO",
    code: "S62R8WW9MT",
    id: "9074N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "B5ADG5LG7L",
  },
  {
    name: "TAILOR DIVANO 2 POSTI SMALL - PIEDI LEGNO",
    code: "0EOSIM2L2E",
    id: "4071N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "5NPTFRPHL8",
  },
  {
    name: "TAILOR DIVANO 2 POSTI SMALL - PIEDI METALLO",
    code: "ZYN5IVLEQE",
    id: "9071N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "RU675DKDEK",
  },
  {
    name: "TAILOR DIVANO 2 POSTI- PIEDI LEGNO",
    code: "KGK5QEPZ06",
    id: "4073N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "XUC0OL7D66",
  },
  {
    name: "TAILOR DIVANO 3 POSTI - PIEDI LEGNO",
    code: "ZD35RPUBK1",
    id: "4075N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "QR94UV2Y7Y",
  },
  {
    name: "TAILOR DIVANO 3 POSTI - PIEDI METALLO",
    code: "XUZR5GWOWV",
    id: "9075N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "HX938CFO4U",
  },
  {
    name: "TAILOR DIVANO 3 POSTI MAJOR - PIEDI LEGNO",
    code: "IUKKDKLSNU",
    id: "4076N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "Q793OGBNVL",
  },
  {
    name: "TAILOR DIVANO 3 POSTI MAJOR - PIEDI METALLO",
    code: "37CZZLVLF4",
    id: "9076N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "ZRVETL79Z9",
  },
  {
    name: "TAILOR POLTRONA - PIEDI LEGNO",
    code: "65H83ZNL73",
    id: "4072N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "MZRJYQC4TM",
  },
  {
    name: "TAILOR POLTRONA - PIEDI METALLO",
    code: "ZNL3AOD0JO",
    id: "9072N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "ZUWNEAQE17",
  },
  {
    name: "TAILOR POLTRONA SMALL - PIEDI LEGNO",
    code: "9170PO5J7R",
    id: "4070N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "MSD21VDH1B",
  },
  {
    name: "TAILOR POLTRONA SMALL - PIEDI METALLO",
    code: "M5NDTGI6UW",
    id: "9070N",
    hasFabric: true,
    family: "Tailor",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL RAL 9005",
    ],
    projectCode: "3YY1USSRWG",
  },
  {
    name: "TAKIMI SOFA",
    code: "QKDAKEUT9A",
    hasFabric: true,
    family: "Takimi",
    hasWood: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9016", "METAL RAL 9005"],
    projectCode: "",
  },
  {
    name: "TIMO POLTRONA",
    code: "WDFLRHHNW2",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "",
  },
  {
    name: "TIMO DIVANETTO PICCOLO PIEDI IN LEGNO",
    code: "1MBRW5NC1N",
    id: "1361",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "91R339HAG5",
  },
  {
    name: "TIMO DIVANETTO PICCOLO PIEDI METALLO",
    code: "NTJFS4XYG4",
    id: "1357",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "UG9TQJRJF8",
  },
  {
    name: "TIMO DIVANO LOUNGE PIEDI IN LEGNO",
    code: "RFHPJGWI3E",
    id: "1363",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "OZMJ3OY4SK",
  },
  {
    name: "TIMO DIVANO LOUNGE PIEDI METALLO",
    code: "N8D21PB4FE",
    id: "1359",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "33GAIEH89W",
  },
  {
    name: "TIMO POLTRONA BASE FODERATA",
    code: "TZUATH8NXM",
    id: "1365",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "ZL57LSZHGJ",
  },
  {
    name: "TIMO POLTRONA LOUNGE IN LEGNO",
    code: "F2KUPLEQIV",
    id: "1362",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "N9K71Q3009",
  },
  {
    name: "TIMO POLTRONA LOUNGE PIEDI METALLO",
    code: "YPG3GVJU8T",
    id: "1358",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "MI0ONTICUO",
  },
  {
    name: "TIMO POLTRONCINA BASE GIREVOLE IN METALLO",
    code: "YZO6XAYKUR",
    id: "1356N",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "IY5IFMHX3T",
  },
  {
    name: "TIMO POLTRONCINA PIEDI IN LEGNO",
    code: "VCN8BYCNX1",
    id: "1360",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "WRI0P5SF38",
  },
  {
    name: "TIMO POLTRONCINA PIEDI METALLO",
    code: "K4VKI345O9",
    id: "1355",
    hasFabric: true,
    family: "Timo",
    hasWood: true,
    customMaterial: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO GRAFITE",
      "METAL AVORIO",
      "METAL PITCH BLACK",
    ],
    projectCode: "RXCDYFO4WQ",
  },
  {
    name: "TURI TURI TAVOLINO DIAMETRO 30 H.50",
    code: "J4WU6V0E8W",
    id: "8770_D30_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "5R5ZC805P7",
  },
  {
    name: "TURI TURI TAVOLINO DIAMETRO 35 H.40",
    code: "BKLWRVI7XG",
    id: "8771_D35_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "F3Y9RXPQ17",
  },
  {
    name: "TURI TURI TAVOLINO DIAMETRO 40 H.45",
    code: "M3KRU7TG21",
    id: "8772_D40_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "PJIKTB4KW1",
  },
  {
    name: "TURI TURI TAVOLINO DIAMETRO 50 H.35",
    code: "1I87GTAYGX",
    id: "8773_D50_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "K3JWM0KJMM",
  },
  {
    name: "TURI TURI TAVOLINO DIAMETRO 70 H.30",
    code: "AW6SSEI8K1",
    id: "8774_D70_TURI",
    hasFabric: false,
    family: "Turi Turi",
    hasWood: true,
    hasPiano: true,
    customPiano: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    projectCode: "74XBKPZIXL",
  },
  {
    name: "TWINGO DIVANO BASSO",
    code: "YAFOFHW2LE",
    id: "1238N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "0G4MSOOI9X",
  },
  {
    name: "TWINGO DIVANO CON PIEDI",
    code: "64BGMTP701",
    id: "1235NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "WCNSS2GF22",
  },
  {
    name: "TWINGO POLTRONA BASSA",
    code: "K97ZRXIXVZ",
    id: "1237N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "VUBVUKWUAY",
  },
  {
    name: "TWINGO POLTRONA CON PIEDI",
    code: "J1484K4PLG",
    id: "1234NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "W23VGXKIBD",
  },
  {
    name: "TWINGO POUFF BASSO",
    code: "7PKTO3CZ99",
    id: "1239N",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "UDI937J75M",
  },
  {
    name: "TWINGO POUFF CON PIEDI",
    code: "OB0MHW1YTB",
    id: "1236NN",
    family: "Twingo",
    hasFabric: true,
    hasWood: true,
    customMaterial: ["ALLUMINIO LUCIDO", "ALLUMINO OPACO", "METAL RAL 9005"],
    projectCode: "4EZYDY3FX2",
  },
  {
    name: "U TAVOLINO LEGNO/METALLO 60 X 40 H.36",
    code: "DB6JIPL08L",
    id: "1105",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "KG3QTH8F8K",
  },
  {
    name: "U TAVOLINO LEGNO/METALLO 75 X 75 H.31",
    code: "MWX6RW7DOJ",
    id: "1106",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "PERT5SPD9D",
  },
  {
    name: "U TAVOLINO LEGNO/METALLO 90 X 40 H.36",
    code: "ESYTQ84WUX",
    id: "1104",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "SHW6E8J2RX",
  },
  {
    name: "U TAVOLINO LEGNO/METALLO DIAM.50 H.41",
    code: "XTYGKZBUSP",
    id: "1107",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "0X4AWUCDBC",
  },
  {
    name: "U TAVOLINO METALLO 60 X 40 H.35",
    code: "2RPMGVL9FV",
    id: "1101",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "6G13NUTTC8",
  },
  {
    name: "U TAVOLINO METALLO 75 X 75 H.30",
    code: "RNMA2EJWK4",
    id: "1102",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "VE6I5HX4HS",
  },
  {
    name: "U TAVOLINO METALLO 90 X 40 H.35",
    code: "2K83NDS81L",
    id: "1100",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "2TOQCHO4HJ",
  },
  {
    name: "U TAVOLINO METALLO DIAM. 50 H.40",
    code: "27B3WMPWXO",
    id: "1103",
    family: "U",
    hasFabric: false,
    hasPiano: true,
    hasWood: true,
    customMaterial: [
      "METAL AVORIO",
      "METAL CIOCCOLATO",
      "METAL FANGO",
      "METAL AZZURRO FIORDALISO",
      "METAL BRONZO",
      "METAL OTTONE",
      "METAL RAME",
      "METAL PITCH BLACK",
      "METAL PRUGNA",
      "METAL TE VERDE",
    ],
    customPiano: ["ROVERE SPAZZOLATO NATURALE", "ROVERE SPAZZOLATO GRAFITE"],
    projectCode: "PNBXWPYFMA",
  },
  {
    name: "UP TAVOLO cm.45x45xH.35",
    code: "3FVOJLLYI8",
    id: "8501_45X45_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "1IHHA5EZJX",
  },
  {
    name: "UP TAVOLO cm.65x45xH.35",
    code: "DTHMSFC1HZ",
    id: "8502_65X45_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "MW7GO4FBHT",
  },
  {
    name: "UP TAVOLO cm.90x90xH.25",
    code: "FS7P21FDYF",
    id: "8506_90X90_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "WUI2O74N6K",
  },
  {
    name: "UP TAVOLO TONDO DIAM. cm.65xH.35",
    code: "QKWHDXOINI",
    id: "8512_D65_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "R4HVHVQMCT",
  },
  {
    name: "UP TAVOLO TONDO DIAM. cm.80xH.25",
    code: "2A74BCSZN4",
    id: "8510_D80_UP",
    family: "Up",
    hasFabric: false,
    hasWood: true,
    hasPiano: true,
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005", "METAL RAL 9016"],
    customPiano: [
      "ROVERE SPAZZOLATO NATURALE",
      "ROVERE SPAZZOLATO WENGE",
      "WOOD RAL 9016",
      "WOOD RAL 9005",
      "MARMO CALACATTA",
      "MARMO MARQUINA",
      "MARMO CARRARA",
    ],
    projectCode: "8BVQON2XLU",
  },
  {
    name: "VELOUR DIVANO 2 POSTI H.80",
    id: "3402/B80",
    code: "QB97A0ZQQZ",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "TQ1DK7RLE3",
  },
  {
    name: "VELOUR DIVANO 3 POSTI H.110",
    id: "3503/B110",
    code: "SOQ22JIK7H",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "0JYASW720U",
  },
  {
    name: "VELOUR DIVANO 3 POSTI H.80",
    id: "3403/B80",
    code: "ZCMEZNOGEY",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "VRDV40H6XF",
  },
  {
    name: "VELOUR DIVANO 3 POSTI MAJOR H.110",
    id: "3504/B110",
    code: "HWUDROAG4R",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "Z3HDQOVHHQ",
  },
  {
    name: "VELOUR DIVANO 3 POSTI MAJOR H.80",
    id: "3404/B80",
    code: "IRY47QCI7N",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "L9W293FAK4",
  },
  {
    name: "VELOUR DORMEUSE 165 DX H.80",
    id: "3417/B80DX",
    code: "4V3LY5PZ3B",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "8XOO1U8B4F",
  },
  {
    name: "VELOUR DORMEUSE 165 SX H.80",
    id: "3419/B80SX",
    code: "GOKNPZ35D1",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "MH7JDF37YJ",
  },
  {
    name: "VELOUR POLTRONA CON PIEDI H.110",
    id: "3500/B110",
    code: "Q5NYQJAA6H",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "OZPYCFGNUO",
  },
  {
    name: "VELOUR POLTRONA GIREVOLE H.110",
    id: "3501/B110N",
    code: "0RQ3IEDF9L",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "QLX1XDCWUR",
  },
  {
    name: "VELOUR POUF 150 X 60",
    id: "3592",
    code: "3ABCPRHYI2",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "3RSSH28UUQ",
  },
  {
    name: "VELOUR SGABELLO BASSO CON PIEDI",
    id: "3306/A",
    code: "W0S04FC3KL",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "I1R0XMALDN",
  },
  {
    name: "VELOUR DIVANO 2 POSTI H.110",
    code: "RZAF1Q2RBW",
    id: "3502/B110",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "TY5J5I4XQT",
  },
  {
    name: "VELOUR DORMEUSE 170 DX H.110",
    code: "S3YF1Q0UKN",
    id: "3517/B110DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "U4GUK68MGR",
  },
  {
    name: "VELOUR DORMEUSE 170 SX H.110",
    code: "BR7YL2F2PI",
    id: "3519/B110SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "K89SGFU50G",
  },
  {
    name: "VELOUR DORMEUSE 195 DX H.80",
    code: "2A598J7P2P",
    id: "3418/B80DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "JG9VT243R0",
  },
  {
    name: "VELOUR DORMEUSE 195 SX H.80",
    code: "7S2UWSF69U",
    id: "3420/B80SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "6IM09VDNJA",
  },
  {
    name: "VELOUR DORMEUSE 200 DX H.110",
    code: "P4AXT7S6J6",
    id: "3518/B110DX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "I5ILJY9SZA",
  },
  {
    name: "VELOUR DORMEUSE 200 SX H.110",
    code: "YRHMOOFMVC",
    id: "3520/B110SX",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "56C00JU1HF",
  },
  {
    name: "VELOUR POLTRONA CON PIEDI H.80",
    code: "XU2VKT5L0G",
    id: "3400/B80",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "AA3J22ITI1",
  },
  {
    name: "VELOUR POLTRONA GIREVOLE H.80",
    code: "DQZZW6RWMW",
    id: "3401/B80N",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "6QF3BCR80F",
  },
  {
    name: "VELOUR POUF 100 X 100",
    code: "8Z93O10TIP",
    id: "3593",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "DCDTGAXGTI",
  },
  {
    name: "VELOUR POUF 100 X 56",
    code: "28Z3GANXRG",
    id: "3591",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "9VS94EPY5J",
  },
  {
    name: "VELOUR POUF 60 X 60",
    code: "9585OBNS8J",
    id: "3590",
    hasFabric: true,
    family: "Velour",
    hasWood: true,
    customMaterial: [
      "FRASSINO NATURALE",
      "FRASSINO TINTA GRAFITE",
      "METAL RAL 9016",
      "METAL RAL 9005",
      "CROMATO LUCIDO",
    ],
    projectCode: "7H3IA49RYB",
  },
  {
    name: "WAVES POUF PARALLEL",
    code: "XJWHTDQ3CR",
    id: "1012",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "M5B6CDF9WO",
  },
  {
    name: "WAVES POUF DIAMOND",
    code: "YFG1UO85LH",
    id: "1010",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "7HUBPECHXZ",
  },
  {
    name: "WAVES DORMEUSE LEAF",
    code: "NGUPO2MYTO",
    id: "1016",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "VE81ULJJVD",
  },
  {
    name: "WAVES POUF DIAMOND CON SCHIENALE",
    code: "Z0WKRZA35M",
    id: "1011",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "B5XJDA9628",
  },
  {
    name: "WAVES POUF HEXAGON",
    code: "2FCLX93FQ1",
    id: "1017",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "HA5VG4Y2Q4",
  },
  {
    name: "WAVES POUF PARALLEL CON SCHIENALE DX",
    code: "NIJQKV6ECO",
    id: "1013DX",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "RK3N050KH7",
  },
  {
    name: "WAVES POUF TRAPEZIUM",
    code: "LR6OWK68VE",
    id: "1014",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "TXYS6VP3MS",
  },
  {
    name: "WAVES POUF TRAPEZIUM CON SCHIENALE",
    code: "G7VTI4LO70",
    id: "1015",
    hasFabric: true,
    hasWood: true,
    family: "Waves",
    customMaterial: ["METAL RAL 7021"],
    projectCode: "OVFXJ33JHO",
  },
  {
    name: "WHY NOT DIVANO 3 POSTI",
    code: "2VKWQZXPR9",
    id: "8451N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "SF85VQWE1H",
  },
  {
    name: "WHY NOT DIVANO 4 POSTI",
    code: "5Q395CKGOC",
    id: "8452N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "0TKD7JMWD2",
  },
  {
    name: "WHY NOT POUFF 190",
    code: "RMZZ82Q1ZE",
    id: "8466N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "1ZVQCFIN1U",
  },
  {
    name: "WHY NOT POUFF R180",
    code: "U3LSMODTRH",
    id: "8467N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "EWJWOG6MFC",
  },
  {
    name: "WHY NOT POUFF R395",
    code: "OO02O5OD06",
    id: "8468N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "J3RI2B1UU0",
  },
  {
    name: "WHY NOT ANGOLO APERTO R.180",
    code: "LJ6JSG0AQW",
    id: "8459N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "9181KMJI9M",
  },
  {
    name: "WHY NOT ANGOLO CHIUSO R.180",
    code: "ZTAE7JDR2U",
    id: "8458N",
    hasFabric: true,
    hasWood: true,
    family: "Why not",
    customMaterial: ["CROMATO LUCIDO", "METAL RAL 9005"],
    projectCode: "49Q56PN7OZ",
  },
  {
    name: "WINDMILL POUF",
    code: "6HVMQ8HMXM",
    hasFabric: true,
    hasWood: false,
    family: "Windmill",
    customCollection: ["Hallingdal 65", "Fame"],
    customMaterial: [],
    projectCode: "HVA0A5YQ9Z",
  },
];

export default products;
