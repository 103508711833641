import React, { useCallback, useEffect, useRef, useState } from "react";
import icon_camera from "./assets/camera.svg";
import icon_plus from "./assets/plus.svg";
import icon_minus from "./assets/minus.svg";
import icon_autoRotate from "./assets/autoRotate.svg";
import icon_back from "./assets/arrowCounterClockwise.svg";

const PlannerPlayer = ({ onLoad, iframeRef }) => {
  const emersyaIframeRef = useRef(null);
  // Stato per tracciare il materiale corrente del pavimento
  const [floorMaterial, setFloorMaterial] = useState("piano");

  // Array dei materiali disponibili
  const floorMaterials = ["piano", "resina", "legno"];

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://cdn.emersya.com/f/emersyaLoader.js";
    script.id = "emersya";
    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  // Funzione per cambiare il materiale del pavimento
  const toggleFloorMaterial = useCallback(() => {
    if (!iframeRef.current) return;

    // Trova l'indice del materiale corrente
    const currentIndex = floorMaterials.indexOf(floorMaterial);
    // Calcola il prossimo indice (torna a 0 se siamo all'ultimo)
    const nextIndex = (currentIndex + 1) % floorMaterials.length;
    // Prendi il prossimo materiale
    const nextMaterial = floorMaterials[nextIndex];

    // Applica il nuovo materiale
    iframeRef.current.contentWindow.postMessage(
      {
        action: "setMaterials",
        materials: [
          {
            materialVariation: nextMaterial,
            configurableMaterial: "DefaultMaterial",
          },
        ],
      },
      "*"
    );

    // Aggiorna lo stato
    setFloorMaterial(nextMaterial);
  }, [floorMaterial, iframeRef]);

  const zoomIn = useCallback(() => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow.postMessage(
      {
        action: "startZoomIn",
      },
      "*"
    );

    setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "stopZoomIn",
        },
        "*"
      );
    }, 800);
  }, [iframeRef]);

  const zoomOut = useCallback(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.postMessage(
      {
        action: "startZoomOut",
      },
      "*"
    );

    setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        {
          action: "stopZoomOut",
        },
        "*"
      );
    }, 600);
  }, [iframeRef]);

  const playRotation = useCallback(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.postMessage(
      {
        action: "play",
      },
      "*"
    );
  }, [iframeRef]);

  const resetCamera = useCallback(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.postMessage(
      {
        action: "resetCamera",
        params: {},
      },
      "*"
    );

    iframeRef.current.contentWindow.postMessage(
      {
        action: "play",
      },
      "*"
    );
  }, [iframeRef]);

  const getScreenshot = useCallback(() => {
    if (!iframeRef.current) return;

    iframeRef.current.contentWindow.postMessage(
      {
        action: "getCamera",
      },
      "*"
    );
  }, [iframeRef]);

  return (
    <div className="ay-relative ay-w-full ay-h-full">
      {/* Controls */}
      <div className="ay-z-10 ay-absolute ay-left-5 ay-bottom-5 ay-flex ay-flex-col ay-gap-3">
        {/* Nuovo bottone per il cambio materiale del pavimento */}
        <button
          onClick={toggleFloorMaterial}
          className="ay-w-8 ay-h-8 ay-bg-white ay-rounded-full ay-flex ay-items-center ay-justify-center ay-shadow-md hover:ay-bg-gray-100"
          title={`Pavimento: ${floorMaterial}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" />
            <path d="M3 9h18" />
            <path d="M9 3v18" />
          </svg>
        </button>
        <img
          onClick={playRotation}
          className="hover:ay-cursor-pointer"
          src={icon_autoRotate}
          alt="Auto Rotate"
        />
        <img
          onClick={zoomIn}
          className="hover:ay-cursor-pointer"
          src={icon_plus}
          alt="Zoom In"
        />
        <img
          onClick={zoomOut}
          className="hover:ay-cursor-pointer"
          src={icon_minus}
          alt="Zoom Out"
        />
        <img
          onClick={resetCamera}
          className="hover:ay-cursor-pointer"
          src={icon_back}
          alt="Reset Camera"
        />
        <img
          onClick={getScreenshot}
          className="hover:ay-cursor-pointer"
          src={icon_camera}
          alt="Screenshot"
        />
      </div>

      {/* Iframe */}
      <iframe
        id="emersyaIframe"
        src="https://emersya.com/showcase/6EX6L8PMJE"
        className="ay-w-full ay-h-full ay-border-none ay-absolute ay-top-0 ay-left-0"
        ref={(el) => {
          emersyaIframeRef.current = el;
          if (iframeRef) iframeRef.current = el;
        }}
        onLoad={onLoad}
      />
    </div>
  );
};

export default PlannerPlayer;
