// src/Planner.jsx
import React, { useState, useEffect, useRef, useMemo } from "react";
import FamilySelector from "./FamilySelector";
import logo from "./assets/logo-laCividina.png";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

import placeholder from "./assets/placeholder.jpg";

// Materiali base
import materiali from "./materials-stuff"; // tessuti, ecc.
import wood from "./collections/wood-metal";

// Tua lista locale di prodotti
import products from "./products";
import PlannerPlayer from "./PlannerPlayer";
import { Md360, MdHeight } from "react-icons/md";
import { IoClose, IoDuplicateSharp } from "react-icons/io5";

// 15° steps per la rotazione
const angles = Array.from({ length: 25 }, (_, i) => i * 15);
const Y_OFFSET = 1.0;

/**
 * Qui la logica principale del Planner
 */
const Planner = () => {
  const iframeRef = useRef(null);
  const emptyImageRef = useRef(null);
  const nodeLocalIdsRef = useRef(1);

  const [loading, setLoading] = useState(true);

  // Dati "projects" dal viewer
  const [categories, setCategories] = useState([]);
  const [productsList, setProductsList] = useState([]); // productNodes da getPlannerUsableProjects
  const [families, setFamilies] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);

  // Stato dei ProductNode istanziati in scena
  const [productNodes, setProductNodes] = useState({});
  const [productNodesToAdd, setProductNodesToAdd] = useState({});

  const [ignoreClick, setIgnoreClick] = useState(false);

  // Stati e toggle per l’UI
  const [isAccordionHidden, setIsAccordionHidden] = useState(false);
  const [floorDimensions, setFloorDimensions] = useState({
    length: 6.0,
    width: 6.0,
    height: 1.0,
  });

  // Gestione menu “Seleziona Modello”
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false);
  const productDropdownRef = useRef(null);

  // Per gestire l’espansione/chiusura degli accordion di materiali
  const [accordionOpenState, setAccordionOpenState] = useState({});

  /* ----------------------------------------------------------------
    1) Setup: listeners per click fuori dal dropdown, immagine empty, ecc.
  -----------------------------------------------------------------*/
  // Chiudo il dropdown se clicco fuori
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        productDropdownRef.current &&
        !productDropdownRef.current.contains(event.target)
      ) {
        setIsProductDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Immagine trasparente (ghost) per il drag
  useEffect(() => {
    const emptyImage = new Image();
    emptyImage.src =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QYHDTQ15hoLGQAAAB1pVFh0Q29tbWVudAAAAAAAQ3JlYXRlZCB3aXRoIEdJTVBkLmUHAAAAC0lEQVQI12NgAAIAAAUAAeImBZsAAAAASUVORK5CYII=";
    emptyImageRef.current = emptyImage;
  }, []);

  // Listener messaggi iframe
  useEffect(() => {
    const handleMessage = (event) => {
      const data = event.data;
      if (!data) return;

      switch (data.action) {
        case "onError":
          handleError(data);
          break;
        case "onSuccess":
          handleSuccess(data);
          break;
        case "onProductNodeInstanceConfigurableMaterialsGet":
          handleConfigurableMaterials(data);
          break;
        case "onCollisionsUpdate":
          handleCollisionsUpdate(data);
          break;
        case "onMeshSelection":
          handleMeshSelection(data);
          break;
        case "onStateChange":
          handleStateChange(data);
          break;
        case "onProductNodeInstanceDraggingUpdate":
          if (productNodes[event.data.nodeLocalId]) {
            const pn = productNodes[event.data.nodeLocalId];
            pn.position = event.data.position;
          }
          if (productNodesToAdd[event.data.nodeLocalId]) {
            const pn = productNodesToAdd[event.data.nodeLocalId];
            pn.position = event.data.position;
          }
          break;

        case "onProductNodeInstanceDraggingStopped":
          // The stop event always follows an update event
          // so we don't need to update position here
          break;
        default:
          // console.log("Unknown action:", data);
          break;
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [productNodes, productNodesToAdd]); // Add these dependencies

  // Al load dell’iframe, mi registro come callback
  const handleIframeLoad = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage(
        { action: "registerCallback" },
        "*"
      );
    }
  };

  /* ----------------------------------------------------------------
     2) HANDLER per errori e successi dal viewer
  -----------------------------------------------------------------*/
  const handleError = (data) => {
    const { callAction, id, message } = data;
    console.error(`[EMERSYA ERROR] in ${callAction}: ${message}`);

    // Se fallisce l’aggiunta del productNode, lo rimuovo dalla coda di "toAdd"
    if (
      callAction === "startProductNodeDrop" ||
      callAction === "addProductNodeInstance" ||
      callAction === "addProductNodesInstances"
    ) {
      setProductNodesToAdd((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
    }
  };

  const [openGroups, setOpenGroups] = useState({});
  const duplicateProductNode = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const localId = nodeLocalIdsRef.current + 1;
    nodeLocalIdsRef.current += 1;

    // Get count of existing products with same projectCode for offset calculation
    const existingCount = Object.values(productNodes).filter(
      (p) => p.projectCode === pn.projectCode
    ).length;

    // Create spiral-like placement for duplicates
    const angle = (existingCount * Math.PI) / 4; // 45-degree increments
    const radius = 0.5; // Base radius
    const offsetPosition = [...pn.position];
    offsetPosition[0] += Math.cos(angle) * radius; // X offset
    offsetPosition[2] += Math.sin(angle) * radius; // Z offset

    // Rest of the duplicate logic remains the same
    const nodes = [
      {
        SKU: pn.SKU,
        projectCode: pn.projectCode,
        localId,
        parentLocalId: 1,
        draggable: true,
        tracked: true,
        position: offsetPosition,
        rotationAxis: [0, 1, 0],
        rotationAngle: pn.rotationAngle || 0,
        configurableMaterials: [],
      },
    ];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "addProductNodesInstances",
        id: localId,
        nodes,
      },
      "*"
    );

    setProductNodesToAdd((prev) => ({
      ...prev,
      [localId]: {
        SKU: pn.SKU,
        projectCode: pn.projectCode,
        localId,
        parentLocalId: 1,
        refData: pn.refData,
        position: offsetPosition,
        rotationAxis: [0, 1, 0],
        rotationAngle: pn.rotationAngle || 0,
        height: pn.height || 1,
      },
    }));
  };

  const renderGroupedProducts = () => {
    // Group products by projectCode
    const groupedProducts = Object.values(productNodes).reduce((groups, pn) => {
      const key = pn.projectCode;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(pn);
      return groups;
    }, {});

    const toggleGroup = (projectCode) => {
      setOpenGroups((prev) => ({
        ...prev,
        [projectCode]: !prev[projectCode],
      }));
    };

    return Object.entries(groupedProducts).map(([projectCode, products]) => {
      const firstProduct = products[0];
      const matched = firstProduct.matchedLocalProduct;
      const isOpen = openGroups[projectCode] !== false; // Default to open

      return (
        <div
          key={projectCode}
          className="ay-p-2 ay-border ay-border-gray-300 ay-rounded ay-relative ay-mb-4"
        >
          {/* Group Header - Only clickable if multiple products */}
          <div
            className={`ay-mb-4 ay-flex ay-justify-between ay-items-center ${
              products.length > 1 ? "ay-cursor-pointer" : ""
            }`}
            onClick={() => products.length > 1 && toggleGroup(projectCode)}
          >
            <h3 className="ay-text-lg ay-font-bold">
              {matched ? matched.name : firstProduct.refData.name}
              {products.length > 1 && ` (${products.length})`}
            </h3>
            {products.length > 1 && (
              <span className="ay-text-xl">{isOpen ? "−" : "+"}</span>
            )}
          </div>

          {/* Individual Products */}
          {(products.length === 1 || isOpen) &&
            products.map((pn, index) => (
              <div
                key={`${pn.localId}-${index}`}
                className={`ay-p-2 ${
                  index > 0 ? "ay-border-t ay-border-gray-200" : ""
                } ${pn.colliding ? "ay-bg-red-200" : ""}`}
              >
                <div
                  className="ay-flex ay-items-center ay-cursor-pointer"
                  onClick={selectProductNodeInstance(pn)}
                >
                  {/* Product Preview */}
                  <div
                    className="ay-w-24 ay-h-24 ay-bg-contain ay-bg-center ay-bg-no-repeat ay-mr-4"
                    style={{
                      backgroundImage: `url(${
                        pn.refData.previewPath || placeholder
                      })`,
                    }}
                  ></div>

                  {/* Controls Section */}
                  <div className="ay-flex-1">
                    {/* Rotation Controls */}
                    <div className="ay-flex ay-flex-col ay-gap-4">
                      <div className="ay-flex ay-items-center">
                        <label className="ay-mr-2">
                          <Md360 />
                        </label>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentAngle = pn.rotationAngle
                              ? Math.round((pn.rotationAngle * 180) / Math.PI)
                              : 0;
                            const newAngle = (currentAngle - 15 + 360) % 360;
                            rotateProductNodeInstance(pn)({
                              target: { value: newAngle },
                              preventDefault: () => {},
                              stopPropagation: () => {},
                            });
                          }}
                          className="ay-px-2 ay-py-1 ay-border  hover:ay-bg-gray-100"
                        >
                          ←
                        </button>
                        <input
                          type="number"
                          min="0"
                          max="360"
                          step="15"
                          className="ay-w-14 ay-p-1 ay-border "
                          value={
                            pn.rotationAngle
                              ? Math.round((pn.rotationAngle * 180) / Math.PI)
                              : 0
                          }
                          onChange={(e) => {
                            const value =
                              ((parseInt(e.target.value) % 360) + 360) % 360;
                            rotateProductNodeInstance(pn)({
                              target: { value },
                              preventDefault: () => {},
                              stopPropagation: () => {},
                            });
                          }}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const currentAngle = pn.rotationAngle
                              ? Math.round((pn.rotationAngle * 180) / Math.PI)
                              : 0;
                            const newAngle = (currentAngle + 15) % 360;
                            rotateProductNodeInstance(pn)({
                              target: { value: newAngle },
                              preventDefault: () => {},
                              stopPropagation: () => {},
                            });
                          }}
                          className="ay-px-2 ay-py-1 ay-border  hover:ay-bg-gray-100"
                        >
                          →
                        </button>
                      </div>

                      {/* Height Control */}
                      <div className="ay-flex ay-items-center ay-gap-2">
                        <label>
                          <MdHeight />
                        </label>
                        <div className="ay-flex ay-items-center ay-gap-2">
                          <input
                            type="number"
                            min="0"
                            max="150"
                            step="1"
                            value={pn.height ? pn.height * 100 : 100}
                            onChange={changeProductNodeInstanceHeight(pn)}
                            className="ay-w-14 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <span className="ay-text-sm ay-text-gray-500">%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action Buttons Column */}
                  <div className="ay-flex ay-flex-col ay-gap-2 ay-ml-4">
                    <button
                      onClick={duplicateProductNode(pn)}
                      className=" ay-text-black ay-rounded-full ay-w-6 ay-h-6 ay-flex ay-items-center ay-justify-center ay-hover:ay-bg-blue-600"
                      title="Duplicate"
                    >
                      <IoDuplicateSharp />
                    </button>
                    <button
                      onClick={removeProductNodeInstance(pn)}
                      className="ay-bg-red-500 ay-text-white ay-rounded-full ay-w-6 ay-h-6 ay-flex ay-items-center ay-justify-center ay-hover:ay-bg-red-600"
                      title="Remove"
                    >
                      <IoClose />
                    </button>
                  </div>
                </div>

                {/* Materials Accordion */}
                <div className="ay-mt-4">
                  {pn.matchedLocalProduct ? (
                    // If we have a match, use the UnifiedMaterialsAccordion with matched product logic
                    <UnifiedMaterialsAccordion
                      pn={pn}
                      matchedProduct={pn.matchedLocalProduct}
                      applyMaterial={applyMaterialFromAccordion}
                      accordionOpenState={accordionOpenState}
                      setAccordionOpenState={setAccordionOpenState}
                    />
                  ) : (
                    // If no match, use the DynamicPlannerAccordion for direct viewer materials
                    <DynamicPlannerAccordion
                      pn={pn}
                      applyMaterial={applyMaterialFromAccordion}
                    />
                  )}
                </div>
              </div>
            ))}
        </div>
      );
    });
  };

  function DynamicPlannerAccordion({ pn, applyMaterial }) {
    const [isOpen, setIsOpen] = useState(false);
    const [openSections, setOpenSections] = useState({});

    // If there are no configurable materials, don't render anything
    if (!pn.configurableMaterials?.length) {
      return null;
    }

    return (
      <div className="ay-border-t ay-border-gray-300 ay-pt-2">
        <div
          className="ay-flex ay-items-center ay-justify-between ay-cursor-pointer ay-mb-2"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h4 className="ay-font-bold">Materiali</h4>
          <span>{isOpen ? "-" : "+"}</span>
        </div>

        {isOpen && (
          <div className="ay-mt-2">
            {pn.configurableMaterials.map((material, index) => (
              <MaterialSection
                key={`${material.name}-${index}`}
                material={material}
                isOpen={openSections[material.name]}
                onToggle={() => {
                  setOpenSections((prev) => ({
                    ...prev,
                    [material.name]: !prev[material.name],
                  }));
                }}
                onSelectMaterial={(variation) => {
                  applyMaterial(pn, material.name, variation.name);
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  function MaterialSection({ material, isOpen, onToggle, onSelectMaterial }) {
    if (!material.materialVariations?.length) {
      return null;
    }

    return (
      <div className="ay-mb-4">
        <div
          className="ay-flex ay-justify-between ay-items-center ay-cursor-pointer ay-py-2 ay-bg-gray-50 ay-rounded"
          onClick={onToggle}
        >
          <span className="ay-font-semibold ay-ml-3">
            {material.name} ({material.materialVariations.length} materiali)
          </span>
          <span className="ay-mr-3">{isOpen ? "-" : "+"}</span>
        </div>

        {isOpen && (
          <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-4">
            {material.materialVariations.map((variation, index) => (
              <div
                key={`${variation.name}-${index}`}
                className="ay-group ay-relative ay-cursor-pointer"
                onClick={() => onSelectMaterial(variation)}
              >
                <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                  {variation.color ? (
                    <div
                      className="ay-aspect-square ay-w-full ay-rounded-lg"
                      style={{ backgroundColor: variation.color }}
                    />
                  ) : (
                    <div className="ay-aspect-square ay-w-full ay-rounded-lg ay-bg-gray-200 ay-flex ay-items-center ay-justify-center">
                      {variation.name}
                    </div>
                  )}
                </div>
                <p className="ay-text-sm ay-mt-2">{variation.name}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  const handleSuccess = (data) => {
    const { callAction, id, data: responseData } = data;
    switch (callAction) {
      case "startProductNodeDrop":
      case "addProductNodeInstance":
      case "addProductNodesInstances":
        if (productNodesToAdd[id]) {
          const pn = productNodesToAdd[id];
          console.log(
            "[SUCCESS] Added productNode with localId =",
            pn.localId,
            " => projectCode =",
            pn.projectCode
          );

          // Add to scene
          setProductNodes((prev) => ({
            ...prev,
            [pn.localId]: {
              ...pn,
              position: [pn.position[0], pn.position[1] + 0.1, pn.position[2]],
            },
          }));

          // Remove from toAdd queue
          setProductNodesToAdd((prev) => {
            const updated = { ...prev };
            delete updated[id];
            return updated;
          });

          // Request configurable materials
          iframeRef.current.contentWindow.postMessage(
            {
              action: "getProductNodeInstanceConfigurableMaterials",
              localId: pn.localId,
            },
            "*"
          );

          // Do direct matching using projectCode
          matchProductWithLocal(pn.localId, pn.projectCode);
        }
        break;
      case "getPlannerUsableProjects":
        setCategories(responseData);
        if (Array.isArray(responseData)) {
          // Estraggo tutti i productNodes
          const allProducts = [];
          const familiesSet = new Set();

          let targetProduct = null;
          responseData.forEach((category) => {
            category.projects.forEach((project) => {
              project.productNodes.forEach((productNode) => {
                const productFamily = project.familyName || category.name || "";
                allProducts.push({
                  ...productNode,
                  projectCode: project.code,
                  family: productFamily,
                  category: category.name,
                });
                familiesSet.add(productFamily);
              });
            });
          });

          setProductsList(allProducts);
          setFamilies(Array.from(familiesSet));

          // Find our target product and add it to the scene
          targetProduct = allProducts.find(
            (p) => p.projectCode === "G3GINJTSNF"
          );
          if (targetProduct) {
            const localId = nodeLocalIdsRef.current + 1;
            nodeLocalIdsRef.current += 1;

            const nodesToAdd = [
              {
                SKU: targetProduct.SKU,
                projectCode: targetProduct.projectCode,
                localId,
                parentLocalId: 1,
                draggable: true,
                tracked: true,
                position: [0.0, 0.174, 0.11], // Centered with increased height
                rotationAxis: [0, 1, 0],
                rotationAngle: 0,
                configurableMaterials: [],
              },
            ];

            // Add to scene
            iframeRef.current.contentWindow.postMessage(
              {
                action: "addProductNodesInstances",
                id: localId,
                nodes: nodesToAdd,
              },
              "*"
            );

            // Add to productNodesToAdd
            setProductNodesToAdd((prev) => ({
              ...prev,
              [localId]: {
                SKU: targetProduct.SKU,
                projectCode: targetProduct.projectCode,
                localId,
                parentLocalId: 1,
                refData: targetProduct,
                position: [0, 0, 0],
                rotationAxis: [0, 1, 0],
                rotationAngle: 0,
                height: 1,
              },
            }));
          }

          console.log("[getPlannerUsableProjects] TOT =", allProducts.length);
          console.log(allProducts); // Log utile
        }
        resizeFloor();
        setLoading(false);
        break;

      case "removeProductNodeInstance":
        setProductNodes((prev) => {
          const updated = { ...prev };
          delete updated[data.id];
          return updated;
        });
        break;

      case "resizePlannerSpace":
        // Nessuna azione specifica
        break;

      default:
        console.log("[EMERSYA] Unhandled success action:", callAction);
    }
  };

  // Funzione di fetch “simile” a quella che facevi in FamilyAccordion
  // per recuperare dal HTML la stringa p/XXXXX/presets e quindi estrarre XXXXX
  const matchProductWithLocal = (localId, projectCodeFromViewer) => {
    console.log(
      `[matchProductWithLocal] localId=${localId}, projectCodeFromViewer=${projectCodeFromViewer}`
    );

    // Try to find a matching product in products.js using projectCode
    const matched = products.find(
      (p) => p.projectCode === projectCodeFromViewer
    );

    // Update productNodes state with the matched product (or null if no match)
    setProductNodes((prev) => {
      const prevPN = prev[localId];
      if (!prevPN) return prev;

      return {
        ...prev,
        [localId]: {
          ...prevPN,
          matchedLocalProduct: matched || null,
        },
      };
    });

    console.log(
      matched
        ? `[MATCH] Found match with products.js => ${matched.name}`
        : `[NO MATCH] No match in products.js for projectCode=${projectCodeFromViewer}`
    );
  };

  /* ----------------------------------------------------------------
     3) HANDLER specializzati su collisions, materials, meshSelection...
  -----------------------------------------------------------------*/
  // Materiali configurabili dal viewer
  const handleConfigurableMaterials = (data) => {
    const { localId, configurableMaterials } = data;
    console.log(
      `[handleConfigurableMaterials] localId=${localId}, => `,
      configurableMaterials
    );

    setProductNodes((prev) => ({
      ...prev,
      [localId]: {
        ...prev[localId],
        configurableMaterials,
      },
    }));
  };

  // Collisioni
  const handleCollisionsUpdate = (data) => {
    const { localIds } = data;
    // localIds => i productNodes che collidono
    setProductNodes((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((localId) => {
        if (localIds.includes(parseInt(localId))) {
          updated[localId].colliding = true;
        } else {
          updated[localId].colliding = false;
        }
      });
      return updated;
    });

    // aggiorno ghost
    const nodesToUpdate = Object.keys(productNodes).map((localId) => ({
      localId: parseInt(localId),
      renderAsGhost: productNodes[localId].colliding,
    }));

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesToGhostRenderingMode",
        nodes: nodesToUpdate,
      },
      "*"
    );
  };

  // Selezione mesh
  const handleMeshSelection = (data) => {
    const { nodeLocalId, position } = data;
    if (productNodes[nodeLocalId]) {
      setProductNodes((prev) => ({
        ...prev,
        [nodeLocalId]: {
          ...prev[nodeLocalId],
          currentHeightFromFloor: position?.[1] || 0,
        },
      }));
      selectProductNodeInstance(productNodes[nodeLocalId])();
    }
  };

  // Gestione stati
  const handleStateChange = (data) => {
    const viewerState = data.state?.viewerState;
    if (viewerState === "loaded") {
      console.log(
        "[onStateChange] => viewerState=loaded => fetch getPlannerUsableProjects"
      );
      iframeRef.current.contentWindow.postMessage(
        {
          action: "getPlannerUsableProjects",
        },
        "*"
      );
    }
  };

  /* ----------------------------------------------------------------
     4) FUNZIONI UTILI: resize floor, drag, click, rimozione, rotazione...
  -----------------------------------------------------------------*/
  // Resizing floor
  const resizeFloor = () => {
    const { length, width, height } = floorDimensions;
    iframeRef.current.contentWindow.postMessage(
      {
        action: "resizePlannerSpace",
        length: parseFloat(length),
        width: parseFloat(width),
        height: parseFloat(height),
      },
      "*"
    );

    // Aggiorno posizioni verticali se necessario
    /*     Object.values(productNodes).forEach((pn) => {
      if (pn.position) {
        const newPosition = [...pn.position];
        newPosition[1] = Y_OFFSET + (pn.height || 1);
        iframeRef.current.contentWindow.postMessage(
          {
            action: "setProductNodesInstancesTransformations",
            nodes: [
              {
                localId: pn.localId,
                position: newPosition,
              },
            ],
          },
          "*"
        );
      }
    }); */
  };

  // Trascino un prodotto (da sinistra)
  const onDragStart = (productNode) => (event) => {
    setIgnoreClick(true);
    setIsProductDropdownOpen(false);

    const localId = nodeLocalIdsRef.current + 1;
    nodeLocalIdsRef.current += 1;

    console.log(
      "[onDragStart] Sto per aggiungere localId=",
      localId,
      productNode
    );

    const nodesToAdd = [
      {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        position: [0, 0.1, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
      },
    ];

    // “startProductNodeDrop” => Emersya lo aggiungerà con drag
    iframeRef.current.contentWindow.postMessage(
      {
        action: "startProductNodeDrop",
        id: localId,
        nodesToAdd,
      },
      "*"
    );

    setProductNodesToAdd((prev) => ({
      ...prev,
      [localId]: {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        refData: productNode,
        position: [0, 0.1, 0],
        rotationAngle: 0,
        configurableMaterials: [],
        height: 1,
      },
    }));

    // Immagine trasparente
    event.dataTransfer.setDragImage(emptyImageRef.current, 0, 0);
    event.preventDefault();
    event.stopPropagation();
  };

  // Click su un prodotto => add immediato
  const onClick = (productNode) => (event) => {
    if (ignoreClick) {
      setIgnoreClick(false);
      return;
    }
    event.preventDefault();
    event.stopPropagation();

    const localId = nodeLocalIdsRef.current + 1;
    nodeLocalIdsRef.current += 1;

    console.log("[onClick] Aggiungo localId=", localId, productNode);

    const nodes = [
      {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        draggable: true,
        tracked: true,
        position: [0, 0.1, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
        configurableMaterials: [],
      },
    ];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "addProductNodesInstances",
        id: localId,
        nodes,
      },
      "*"
    );

    setProductNodesToAdd((prev) => ({
      ...prev,
      [localId]: {
        SKU: productNode.SKU,
        projectCode: productNode.projectCode,
        localId,
        parentLocalId: 1,
        refData: productNode,
        position: [0, 0, 0],
        rotationAxis: [0, 1, 0],
        rotationAngle: 0,
        height: 1,
      },
    }));
  };

  // Rimuovo prodotto
  const removeProductNodeInstance = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("[removeProductNodeInstance] => localId=", pn.localId);
    iframeRef.current.contentWindow.postMessage(
      {
        action: "removeProductNodeInstance",
        localId: pn.localId,
        id: pn.localId,
      },
      "*"
    );
  };

  // Ruoto prodotto
  const rotateProductNodeInstance = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();
    const angleDeg = parseInt(event.target.value);
    const angleRad = (angleDeg * Math.PI) / 180;

    setProductNodes((prev) => ({
      ...prev,
      [pn.localId]: {
        ...prev[pn.localId],
        rotationAngle: angleRad,
      },
    }));

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesInstancesTransformations",
        nodes: [
          {
            localId: pn.localId,
            rotationAxis: [0, 1, 0],
            rotationAngle: angleRad,
          },
        ],
      },
      "*"
    );
  };

  // Altezza prodotto
  const changeProductNodeInstanceHeight = (pn) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const oldHeight = pn.height || 1;
    const newHeight = parseInt(event.target.value) / 100;

    // Important: Keep the existing position and only update Y
    const currentPosition = pn.position || [0, 0, 0];
    const newPosition = [
      currentPosition[0],
      currentPosition[1] + (newHeight - oldHeight),
      currentPosition[2],
    ];

    iframeRef.current.contentWindow.postMessage(
      {
        action: "setProductNodesInstancesTransformations",
        nodes: [
          {
            localId: pn.localId,
            position: newPosition,
            height: newHeight,
          },
        ],
      },
      "*"
    );

    // Update state with both new height and position
    setProductNodes((prev) => ({
      ...prev,
      [pn.localId]: {
        ...prev[pn.localId],
        height: newHeight,
        position: newPosition,
      },
    }));
  };

  // Seleziono prodotto
  const selectProductNodeInstance = (pn) => () => {
    iframeRef.current.contentWindow.postMessage(
      {
        action: "selectProductNode",
        localId: pn.localId,
      },
      "*"
    );
  };

  // Applico materiale => Messaggio a Emersya
  const applyMaterial = (pn, configurableMaterial, materialVariation) => {
    console.log(
      `[applyMaterial] localId=${pn.localId}, configurableMaterial=${configurableMaterial}, variation=${materialVariation}`
    );
    iframeRef.current.contentWindow.postMessage(
      {
        action: "setMaterials",
        materials: [
          {
            configurableMaterial,
            materialVariation,
            nodeLocalIds: [pn.localId],
          },
        ],
      },
      "*"
    );
  };

  /* ----------------------------------------------------------------
     5) SHIFT => snapping
  -----------------------------------------------------------------*/
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        iframeRef.current.contentWindow.postMessage(
          { action: "togglePlannerSnapMode", value: true },
          "*"
        );
      }
    };
    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        iframeRef.current.contentWindow.postMessage(
          { action: "togglePlannerSnapMode", value: false },
          "*"
        );
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  /* ----------------------------------------------------------------
     6) Filtri e UI => Filtro prodotti per "FamilySelector" e dropdown
  -----------------------------------------------------------------*/
  const filteredProducts = productsList.filter((product) => {
    if (!selectedFamily) return true;
    return product.family === selectedFamily;
  });

  const handleFamilyChange = (newFamilyName) => {
    setSelectedFamily(newFamilyName);
    setIsProductDropdownOpen(false);
  };

  const toggleAccordionVisibility = () => {
    setIsAccordionHidden((prevState) => !prevState);
    setTimeout(() => {
      iframeRef.current?.contentWindow?.postMessage(
        {
          action: "resizePlannerSpace",
        },
        "*"
      );
    }, 150);
  };

  /* ----------------------------------------------------------------
     7) RENDER
  -----------------------------------------------------------------*/
  return (
    <>
      {loading && (
        <div
          className="ay-w-full ay-h-screen ay-bg-white ay-opacity-95 ay-fixed ay-top-0 ay-z-50 ay-flex ay-justify-center ay-items-center ay-text-3xl"
          id="loader"
        >
          <img
            src={logo}
            alt="Logo laCividina"
            className="ay-animate-scale-pulse"
          />
        </div>
      )}

      <div className="ay-flex ay-flex-col md:ay-flex-row ay-h-screen ay-overflow-hidden">
        {/* IFRAME */}
        <div
          className={`ay-relative ${
            isAccordionHidden ? "ay-w-full" : "ay-w-full md:ay-w-4/5"
          } ay-transition-all ay-duration-500 ay-ease-in-out ay-h-screen`}
        >
          <PlannerPlayer iframeRef={iframeRef} onLoad={handleIframeLoad} />

          <button
            onClick={toggleAccordionVisibility}
            className={`ay-absolute ay-transition-transform ay-duration-300 ${
              isAccordionHidden ? "ay-rotate-180" : ""
            } ay-right-4 ay-opacity-20 ay-top-1/2 ay-transform ay--translate-y-1/2 ay-text-3xl ay-font-bold ay-focus:ay-outline-none`}
          >
            <FaChevronRight />
          </button>
        </div>

        {/* SIDEBAR / UI */}
        <div
          className={`ay-p-5 ay-bg-[#f9f9f9]  ay-select-none ay-overflow-auto ${
            isAccordionHidden ? "ay-w-0" : "ay-w-full md:ay-w-3/5 lg:ay-w-2/6"
          } ay-transition-all ay-duration-500 ay-ease-in-out`}
          style={{ display: isAccordionHidden ? "none" : "block" }}
        >
          <div className="ay-flex ay-flex-col ay-h-full">
            {/* Famiglia */}
            <FamilySelector
              products={productsList}
              selectedFamily={selectedFamily}
              onFamilyChange={handleFamilyChange}
              onProductChange={() => {}}
            />

            {/* Dropdown “Seleziona modello” */}
            <div className="ay-mt-4 ay-group ay-gap-x-4 ay-border-b ay-border-black ay-pb-4">
              <h2 className="ay-text-lg ay-font-bold ay-mb-2">
                Seleziona modello
              </h2>
              <div className="ay-relative" ref={productDropdownRef}>
                <div
                  className="ay-border ay-rounded-lg ay-bg-white ay-border-gray-200 ay-w-full ay-p-4 ay-flex ay-justify-between ay-items-center ay-cursor-pointer"
                  onClick={() =>
                    setIsProductDropdownOpen(!isProductDropdownOpen)
                  }
                >
                  <span>
                    {selectedFamily
                      ? `Famiglia: ${selectedFamily} (${filteredProducts.length})`
                      : `Tutti i prodotti (${filteredProducts.length})`}
                  </span>
                  <span
                    className={`ay-transition-transform ay-duration-300 ${
                      isProductDropdownOpen ? "ay-rotate-180" : ""
                    }`}
                  >
                    <FaChevronDown />
                  </span>
                </div>

                {isProductDropdownOpen && (
                  <div className="ay-absolute ay-z-10 ay-w-full ay-mt-1 ay-bg-white ay-border ay-border-gray-200 ay-rounded-lg ay-shadow-lg ay-max-h-[60vh] ay-overflow-auto">
                    {filteredProducts.length > 0 ? (
                      filteredProducts.map((product, i) => {
                        // Cerchiamo un match nel products.js usando il projectCode
                        const matchedProduct = products.find(
                          (p) => p.projectCode === product.projectCode
                        );

                        // Usiamo il nome dal match se disponibile, altrimenti il nome originale
                        const displayName = matchedProduct
                          ? matchedProduct.name
                          : product.name;

                        return (
                          <div
                            key={`${product.projectCode}-${product.SKU}-${i}`}
                            className="ay-p-4 ay-hover:bg-gray-100 ay-cursor-pointer ay-flex ay-items-center"
                            draggable
                            onDragStart={onDragStart(product)}
                            onClick={onClick(product)}
                            onPointerDown={() => setIgnoreClick(false)}
                          >
                            <img
                              src={product.previewPath || placeholder}
                              alt={displayName}
                              className="ay-w-12 ay-h-12 ay-object-cover ay-mr-4"
                            />
                            <span className="ay-flex-1 ay-truncate">
                              {displayName}
                              {matchedProduct && (
                                <span className="ay-text-xs ay-text-gray-500 ay-block">
                                  {product.projectCode}
                                </span>
                              )}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div className="ay-p-4 ay-text-gray-500">
                        Nessun prodotto disponibile per la famiglia selezionata.
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Dimensioni Floor */}
            <div className="ay-mb-6 ay-flex ay-mt-6 ay-flex-row ay-gap-2">
              <div className="ay-flex ay-items-center ay-mb-2">
                <label
                  htmlFor="lengthInput"
                  className="ay-w-auto ay-text-right ay-mr-2"
                >
                  L
                </label>
                <input
                  type="number"
                  id="lengthInput"
                  defaultValue={6.0}
                  min={1.0}
                  max={10.0}
                  step={0.5}
                  onChange={(e) =>
                    setFloorDimensions((prev) => ({
                      ...prev,
                      length: parseFloat(e.target.value),
                    }))
                  }
                  onBlur={resizeFloor}
                  className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
                />
              </div>
              <div className="ay-flex ay-items-center ay-mb-2">
                <label
                  htmlFor="widthInput"
                  className="ay-w-auto ay-text-right ay-mr-2"
                >
                  W
                </label>
                <input
                  type="number"
                  id="widthInput"
                  defaultValue={6.0}
                  min={1.0}
                  max={10.0}
                  step={0.5}
                  onChange={(e) =>
                    setFloorDimensions((prev) => ({
                      ...prev,
                      width: parseFloat(e.target.value),
                    }))
                  }
                  onBlur={resizeFloor}
                  className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
                />
              </div>
              <div className="ay-flex ay-items-center ay-mb-2">
                <label
                  htmlFor="heightInput"
                  className="ay-w-auto ay-text-right ay-mr-2"
                >
                  H
                </label>
                <input
                  type="number"
                  id="heightInput"
                  defaultValue={1}
                  min={1.0}
                  max={10.0}
                  step={0.5}
                  onChange={(e) =>
                    setFloorDimensions((prev) => ({
                      ...prev,
                      height: parseFloat(e.target.value),
                    }))
                  }
                  onBlur={resizeFloor}
                  className="ay-w-20 ay-p-1 ay-border ay-border-gray-300 ay-rounded"
                />
              </div>
            </div>

            {/* Prodotti aggiunti in scena */}
            <div className="ay-mb-4">
              <h2 className="ay-text-xl ay-font-semibold ay-mb-2">
                Added Products
              </h2>
              {Object.values(productNodes).length > 0 ? (
                renderGroupedProducts()
              ) : (
                <p className="ay-text-gray-500">No products added yet.</p>
              )}
            </div>

            {/* Logo */}
            <img
              src={logo}
              alt="Logo laCividina"
              className="ay-w-5/6 ay-pt-6 ay-mt-auto"
            />
          </div>
        </div>
      </div>
    </>
  );

  /**
   * Funzione di wrapper per "applyMaterial", in modo che
   * la passiamo al componente di accordion con la firma che preferiamo:
   *   applyMaterial(pn, "imbottito" | "struttura" | ecc, "NOME VARIANTE")
   */
  function applyMaterialFromAccordion(pn, configurableMat, variationName) {
    applyMaterial(pn, configurableMat, variationName);
  }
};

export default Planner;

/* ===========================================================================
   COMPONENTE: UnifiedMaterialsAccordion
   ===========================================================================
   Dato un productNode (pn) e un eventuale matchedProduct (dalla tua products.js),
   mostriamo un unico accordion che:
     - Se c’è matchedProduct => “Accordion stile App.js” (hasFabric, hasWood, etc.)
     - Se NON c’è matchedProduct => “Accordion stile Emersya” (pn.configurableMaterials)
   In entrambi i casi, la resa grafica è simile: un pannello con collezioni e thumbnails.
=========================================================================== */

function UnifiedMaterialsAccordion({
  pn,
  matchedProduct, // Se null => no match
  applyMaterial,
  accordionOpenState,
  setAccordionOpenState,
}) {
  // Se c’è matched, scomponiamo in 2-3 sezioni (Rivestimento, Struttura, Finitura, etc.).
  // Se non c’è matched, usiamo i configurableMaterials del viewer.

  const localId = pn.localId;
  const isAccordionOpen = accordionOpenState[localId] || false;

  // Toggle principale (apri/chiudi l’intero blocco di materiali)
  const toggleAccordion = () => {
    setAccordionOpenState((prev) => ({
      ...prev,
      [localId]: !prev[localId],
    }));
  };

  return (
    <div className="ay-border-t ay-border-gray-300 ay-pt-2">
      <div
        className="ay-flex ay-items-center ay-justify-between ay-cursor-pointer ay-mb-2"
        onClick={toggleAccordion}
      >
        <h4 className="ay-font-bold">
          {matchedProduct ? "Materiali" : "Materiali"}
        </h4>
        <span>{isAccordionOpen ? "-" : "+"}</span>
      </div>

      {isAccordionOpen && (
        <div className="ay-mt-2">
          {matchedProduct ? (
            // --- Se c'è match => come la logica "Accordion.js" con i vari hasFabric, hasWood, ...
            <div>
              {matchedProduct.hasFabric && (
                <AccordionSection
                  pn={pn}
                  title="Rivestimento"
                  configurableMaterialName="imbottito"
                  customConfigCollection={matchedProduct.customCollection}
                  customConfigMaterial={matchedProduct.customMaterial}
                  applyMaterial={applyMaterial}
                  type="imbottito"
                />
              )}
              {matchedProduct.hasWood && (
                <AccordionSection
                  pn={pn}
                  title="Struttura"
                  configurableMaterialName="struttura"
                  customConfigMaterial={matchedProduct.customMaterial}
                  applyMaterial={applyMaterial}
                />
              )}
              {matchedProduct.hasPiano && (
                <AccordionSection
                  pn={pn}
                  title="Finitura"
                  configurableMaterialName="finitura"
                  customConfigMaterial={matchedProduct.customPiano}
                  applyMaterial={applyMaterial}
                />
              )}
            </div>
          ) : (
            // --- Se non c’è match => prendo pn.configurableMaterials e li mostro
            <div>
              {Array.isArray(pn.configurableMaterials) &&
              pn.configurableMaterials.length ? (
                pn.configurableMaterials.map((confMat, i) => (
                  <AccordionSectionNoMatch
                    key={i}
                    pn={pn}
                    confMat={confMat}
                    applyMaterial={applyMaterial}
                  />
                ))
              ) : (
                <p className="ay-text-sm ay-text-gray-500">
                  Nessun materiale configurabile
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

/* ===========================================================================
   Sezione Accordion per MATCH locale
   (replica logica di "material" e "wood" in base a "configurableMaterialName")
=========================================================================== */
function AccordionSection({
  pn,
  title,
  configurableMaterialName,
  customConfigCollection,
  customConfigMaterial,
  applyMaterial,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openCollections, setOpenCollections] = useState({});
  const [filterText, setFilterText] = useState("");

  // 1. Troviamo il configurableMaterial corrispondente
  const matchingConfigMaterial = pn.configurableMaterials?.find(
    (cm) => cm.name === configurableMaterialName
  );

  // 2. Set delle varianti disponibili
  const availableVariations = new Set(
    matchingConfigMaterial?.materialVariations?.map((v) => v.name) || []
  );

  // 3. Base materiali (rivestimento o struttura)
  let baseData = configurableMaterialName === "imbottito" ? materiali : wood;

  // 4. Filtraggio dei materiali
  const filteredMaterials = useMemo(() => {
    // Prima filtriamo per collection se specificato
    let filtered = baseData;
    if (customConfigCollection) {
      filtered = filtered.filter((m) =>
        customConfigCollection.includes(m.collection)
      );
    }

    // Poi filtriamo per categoria se selezionata
    if (selectedCategory) {
      filtered = filtered.filter((m) => m.category === selectedCategory);
    }

    // Infine, filtriamo per le varianti disponibili
    filtered = filtered
      .map((m) => ({
        ...m,
        items: m.items.filter((item) => availableVariations.has(item.name)),
      }))
      .filter((m) => m.items.length > 0);

    return filtered;
  }, [baseData, customConfigCollection, selectedCategory, availableVariations]);

  // 5. Gestione ricerca
  const searchResults = useMemo(() => {
    if (!filterText.trim()) return [];

    return filteredMaterials
      .flatMap((material) => material.items)
      .filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase())
      );
  }, [filterText, filteredMaterials]);

  // 6. Helper per categorie uniche
  const uniqueCategories = useMemo(() => {
    return [...new Set(filteredMaterials.map((m) => m.category))];
  }, [filteredMaterials]);

  // 7. Handlers
  const handleCollectionToggle = (collection) => {
    setOpenCollections((prev) => ({
      ...prev,
      [collection]: !prev[collection],
    }));
  };

  return (
    <div className="ay-mb-2">
      <div
        className="ay-flex ay-justify-between ay-items-center ay-cursor-pointer ay-py-2 ay-bg-gray-100 ay-rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="ay-font-semibold ay-ml-3">
          {title} (
          {filteredMaterials.reduce((acc, m) => acc + m.items.length, 0)}{" "}
          materiali)
        </span>
        <span className="ay-mr-3">{isOpen ? "-" : "+"}</span>
      </div>

      {isOpen && (
        <div className="ay-mt-2 ay-bg-white ay-rounded ay-p-3">
          {/* Dropdown categorie (solo per rivestimenti) */}
          {configurableMaterialName === "imbottito" &&
            uniqueCategories.length > 0 && (
              <select
                value={selectedCategory || ""}
                onChange={(e) => setSelectedCategory(e.target.value || null)}
                className="ay-border ay-rounded-lg ay-border-black ay-w-full ay-p-4 ay-mb-4"
              >
                <option value="">TUTTE LE CATEGORIE</option>
                {uniqueCategories.map((category) => (
                  <option key={category} value={category}>
                    {category.toUpperCase()}
                  </option>
                ))}
              </select>
            )}

          {/* Ricerca (solo per rivestimenti) */}
          {configurableMaterialName === "imbottito" && (
            <input
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder={`Cerca ${title.toLowerCase()}...`}
              className="ay-border ay-rounded-lg ay-w-full ay-p-4 ay-mb-4"
            />
          )}

          {/* Risultati ricerca */}
          {searchResults.length > 0 && (
            <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mb-4">
              {searchResults.map((item) => (
                <div
                  key={item.slug}
                  className="ay-group ay-relative ay-cursor-pointer"
                  onClick={() =>
                    applyMaterial(pn, configurableMaterialName, item.name)
                  }
                >
                  <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                    <img
                      className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                      src={item.thumbnail || placeholder}
                      alt={item.name}
                      style={{ transformOrigin: "center" }}
                    />
                  </div>
                  <p className="ay-text-sm ay-mt-2">{item.name}</p>
                </div>
              ))}
            </div>
          )}

          {/* Collezioni e materiali */}
          {filteredMaterials.map((material, idx) => (
            <div key={idx} className="ay-mb-4">
              <div
                className="ay-cursor-pointer ay-border-b ay-border-gray-300 ay-py-1.5"
                onClick={() => handleCollectionToggle(material.collection)}
              >
                <h5 className="ay-font-bold ay-flex ay-justify-between">
                  {material.collection.toUpperCase()}
                  <span>
                    {openCollections[material.collection] ? "-" : "+"}
                  </span>
                </h5>
              </div>

              {openCollections[material.collection] && (
                <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2">
                  {material.items.map((item) => (
                    <div
                      key={item.slug}
                      className="ay-group ay-relative ay-cursor-pointer"
                      onClick={() =>
                        applyMaterial(pn, configurableMaterialName, item.name)
                      }
                    >
                      <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                        <img
                          className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                          src={item.thumbnail || placeholder}
                          alt={item.name}
                          style={{ transformOrigin: "center" }}
                        />
                      </div>
                      <p className="ay-text-sm ay-mt-2">{item.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

/* ===========================================================================
   Sezione Accordion per NO MATCH => uso configurableMaterials
=========================================================================== */
function AccordionSectionNoMatch({ pn, confMat, applyMaterial }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [openCollections, setOpenCollections] = useState({});
  const [filterText, setFilterText] = useState("");

  // Set di nomi materiali dalle variazioni
  const availableVariations = new Set(
    confMat.materialVariations.map((v) => v.name)
  );

  // Selezione base materiali
  let baseData = [];
  if (confMat.name === "imbottito") {
    baseData = materiali;
  } else if (
    confMat.name === "struttura" ||
    confMat.name === "piano" ||
    confMat.name === "finitura"
  ) {
    baseData = wood;
  }

  // Filtraggio dei materiali con useMemo
  const filteredMaterials = useMemo(() => {
    // Prima filtriamo per categoria se selezionata
    let filtered = baseData;
    if (selectedCategory) {
      filtered = filtered.filter((m) => m.category === selectedCategory);
    }

    // Poi filtriamo per le varianti disponibili
    filtered = filtered
      .map((m) => ({
        ...m,
        items: m.items.filter((item) => availableVariations.has(item.name)),
      }))
      .filter((m) => m.items.length > 0);

    return filtered;
  }, [baseData, selectedCategory, availableVariations]);

  // Gestione ricerca
  const searchResults = useMemo(() => {
    if (!filterText.trim()) return [];

    return filteredMaterials
      .flatMap((material) => material.items)
      .filter((item) =>
        item.name.toLowerCase().includes(filterText.toLowerCase())
      );
  }, [filterText, filteredMaterials]);

  // Helper per categorie uniche
  const uniqueCategories = useMemo(() => {
    return [...new Set(filteredMaterials.map((m) => m.category))]
      .filter(Boolean) // Rimuove eventuali undefined/null
      .sort(); // Ordina alfabeticamente
  }, [filteredMaterials]);

  // Toggle collezione
  const handleCollectionToggle = (collection) => {
    setOpenCollections((prev) => ({
      ...prev,
      [collection]: !prev[collection],
    }));
  };

  return (
    <div className="ay-mb-2">
      <div
        className="ay-flex ay-justify-between ay-items-center ay-cursor-pointer ay-py-2 ay-bg-gray-50 ay-rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="ay-font-semibold ay-ml-3">
          {confMat.name} (
          {filteredMaterials.reduce((acc, m) => acc + m.items.length, 0)}{" "}
          materiali)
        </span>
        <span className="ay-mr-3">{isOpen ? "-" : "+"}</span>
      </div>

      {isOpen && (
        <div className="ay-mt-2 ay-bg-white ay-rounded ay-p-3">
          {/* Dropdown categorie (solo per rivestimenti) */}
          {confMat.name === "imbottito" && uniqueCategories.length > 0 && (
            <select
              value={selectedCategory || ""}
              onChange={(e) => setSelectedCategory(e.target.value || null)}
              className="ay-border ay-rounded-lg ay-border-black ay-w-full ay-p-4 ay-mb-4"
            >
              <option value="">TUTTE LE CATEGORIE</option>
              {uniqueCategories.map((category) => (
                <option key={category} value={category}>
                  {category.toUpperCase()}
                </option>
              ))}
            </select>
          )}

          {/* Ricerca (solo per rivestimenti) */}
          {confMat.name === "imbottito" && (
            <input
              type="text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              placeholder={`Cerca ${confMat.name}...`}
              className="ay-border ay-rounded-lg ay-w-full ay-p-4 ay-mb-4"
            />
          )}

          {/* Risultati ricerca */}
          {searchResults.length > 0 && (
            <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mb-4">
              {searchResults.map((item) => (
                <div
                  key={item.slug}
                  className="ay-group ay-relative ay-cursor-pointer"
                  onClick={() => applyMaterial(pn, confMat.name, item.name)}
                >
                  <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                    <img
                      className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                      src={item.thumbnail || placeholder}
                      alt={item.name}
                      style={{ transformOrigin: "center" }}
                    />
                  </div>
                  <p className="ay-text-sm ay-mt-2">{item.name}</p>
                </div>
              ))}
            </div>
          )}

          {/* Collezioni e materiali */}
          {filteredMaterials.map((material, idx) => (
            <div key={idx} className="ay-mb-4">
              <div
                className="ay-cursor-pointer ay-border-b ay-border-gray-300 ay-py-1.5"
                onClick={() => handleCollectionToggle(material.collection)}
              >
                <h5 className="ay-font-bold ay-flex ay-justify-between">
                  {material.collection.toUpperCase()}
                  <span>
                    {openCollections[material.collection] ? "-" : "+"}
                  </span>
                </h5>
              </div>

              {openCollections[material.collection] && (
                <div className="ay-grid ay-grid-cols-2 md:ay-grid-cols-3 lg:ay-grid-cols-4 ay-gap-4 ay-mt-2">
                  {material.items.map((item) => (
                    <div
                      key={item.slug}
                      className="ay-group ay-relative ay-cursor-pointer"
                      onClick={() => applyMaterial(pn, confMat.name, item.name)}
                    >
                      <div className="ay-overflow-hidden ay-rounded-lg hover:ay-border-gray-900 hover:ay-border-2">
                        <img
                          className="ay-aspect-square ay-w-full ay-rounded-lg ay-object-cover ay-group-hover:ay-border ay-scale-125 group-hover:ay-scale-200 ay-transition-transform"
                          src={item.thumbnail || placeholder}
                          alt={item.name}
                          style={{ transformOrigin: "center" }}
                        />
                      </div>
                      <p className="ay-text-sm ay-mt-2">{item.name}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          {filteredMaterials.length === 0 && (
            <p className="ay-text-sm ay-text-gray-500">
              Nessun materiale disponibile per {confMat.name}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
