import "./index.css";
import App from "./App.js";
import JsonEditorPage from "./JsonEditorPage";
import { createRoot } from "react-dom/client";
import Planner from "./Planner.js";

const root = createRoot(document.querySelector("#ayea-player"));

const renderPage = () => {
  // Check for the hidden input with a mode attribute
  const modeInput = document.querySelector('input[type="hidden"][mode]');
  let mode = modeInput ? modeInput.getAttribute("mode") : null;

  // Use mode if set: "0" for normal, "1" for planner, "2" for JSON editor
  if (mode === "1") {
    root.render(<Planner />);
    return;
  } else if (mode === "2") {
    root.render(<JsonEditorPage />);
    return;
  }

  // If mode is "0" or not specified, follow hash-based routing
  const hash = window.location.hash || "#/";
  if (hash === "#/json") {
    root.render(<JsonEditorPage />);
  } else if (hash === "#/planner") {
    root.render(<Planner />);
  } else {
    const productId = document.getElementById("ayea-product-id").value;
    root.render(<App productId={productId} />);
  }
};

// Initial render
renderPage();

// Listen for hash changes
window.addEventListener("hashchange", renderPage);
